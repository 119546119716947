<template lang="pug">
div
  b-spinner(v-if='loading', label='Loading...')
  b-alert.mt-2.w-50.m-auto(
    show,
    variant='danger',
    v-if='!loading && pipeNames && pipeNames.length == 0'
  ) You have not been granted access to any systems, please contact system admin.
  b-row.justify-content-center.mt-5.mb-5.ml-2.mr-2(
    v-if='pipeNames && pipeNames.length > 0',
    sm='auto'
  )
    b-col(v-for='(pipe, index) in pipeNames', :key='index', sm='auto')
      b-card(
        :title='pipe.name',
        :img-src='image',
        img-top,
        v-on:click='setPipe(pipe.name)',
        :class='selected == pipe.name ? "sel" : ""'
      )
        b-card-text
          | {{ pipe.tag }}
        //- b-list-group
        //-     b-list-group-item(v-if="isLogged && userPaths.indexOf('Stats') !== -1" :to='{path:"/statistics"}' @click="setPipe(pipe.name)") Statistics
        //-     b-list-group-item(v-if="isLogged && userPaths.indexOf('Images') !== -1" :to='{path:"/images"}' @click="setPipe(pipe.name)") Images
        //-     b-list-group-item(v-if="isLogged && userPaths.indexOf('Preview') !== -1" :to='{path:"/preview"}' @click="setPipe(pipe.name)") Preview
        //-     b-list-group-item(v-if="isLogged && userPaths.indexOf('Feed') !== -1" :to='{path:"/live"}' @click="setPipe(pipe.name)") Streams
</template>

<script>
import {
  PIPE_SELECTION_CHANGE,
  GET_CURRENT_USER_PERMS,
  GET_SOURCE_TAGS,
} from '../store/constants'
export default {
  data() {
    return {
      userPaths: [],
      image: require('/src/assets/fish.png'),
      pipeNames: this.getPipeNames,
      selected: null,
      pipeComp: [],
      loading: true,
    }
  },
  created() {
    this.loading = true
  },
  mounted() {
    this.getPipes()
    this.selected = this.$store.getters.getCurrentLocation
    this.userPaths = this.$store.getters.getPerm
  },
  computed: {
    isLogged() {
      return this.$store.getters.auth
    },
    getPerms() {
      return this.$store.getters.getPerm
    },
  },
  watch: {
    selected: function (newValue) {
      this.$store.dispatch(PIPE_SELECTION_CHANGE, newValue).then(() => {
        this.$forceUpdate()
      })
    },
    getPerms: function (newValue) {
      this.userPaths = newValue
    },
  },
  methods: {
    setPipe(pipe) {
      if (pipe !== this.selected) {
        this.selected = pipe
      }
      setTimeout(() => {
        this.$router.push('/statistics')
      }, 350)
    },

    async getPipes() {
      this.pipeNames = []
      var pipeNames = []
      var result = await this.$store.dispatch(GET_CURRENT_USER_PERMS)
      result.forEach((element) => {
        var item = element.split(' ')
        if (!pipeNames.includes(item[1])) pipeNames.push(item[1])
      })
      result = await this.$store.dispatch(GET_SOURCE_TAGS)
      result.forEach((element) => {
        if (pipeNames.includes(element.name)) {
          this.pipeNames.push(element)
        }
      })
      this.loading = false
    },
  },
}
</script>

<style scoped lang="stylus">
.card:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.card {
  transition: all 0.1s ease-in-out;
}

.sel {
  border: 4px solid green;
}

.bg {
  background: url('../assets/bg.jpg') no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
}

.selected {
  transform: scale(0.8);
}

.card-img-top {
  height: 50%;
  width: 50%;
  margin: auto;
}
</style>
