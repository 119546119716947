<template lang="pug">
div
  b-row.justify-content-center
    b-col(sm='auto')
      b-row.justify-content-center
        span(style='font-size: 1.2em') {{ imageDate }}
      b-row.justify-content-center
        #bbox-entry.p-1.ml-1
          b-list-group.p-0(small)
            b-list-group-item.m-0.p-1(
              v-for='(boundingbox, index) in BoundingBoxes',
              :key='index',
              @mouseover='focusIndex = index',
              @click='focusIndex = index',
              :class='focusIndex === index && !boundingbox.skip ? "bbox-selected" : boundingbox.skip ? "bbox-skipped" : ""'
            ) 
              b-button.p-1.mr-2(
                variant='danger',
                square,
                @click='removeSelectedBox(boundingbox)'
              ) X
              b-form-select.w-auto.ml-1.mr-1(
                @change='clickLabelChange',
                :value='cOptions[focusIdxClass[index]]',
                :options='cOptions',
                size='sm',
                ref='classes',
                :class='focusIndex == index && focusElement == "class" ? "input-selected" : ""',
                @click='focusElement = "class"'
              )
              b-form-select.w-auto.ml-1.mr-1(
                @change='clickSizeChange',
                :value='sOptions[focusIdxSize[index]]',
                :options='sOptions',
                size='sm',
                ref='sizes',
                :class='focusIndex == index && focusElement == "size" ? "input-selected" : ""',
                @click='focusElement = "size"'
              )

    b-col(sm='auto')
      #container.m-3.image
        //b-icon(v-if="loading", icon="arrow-clockwise", animation="spin", font-scale="4")
        img#image.img-fluid(
          :class='isMobile ? "img-mobile" : "img-desktop"',
          :src='`data:image/png;base64, ${Source.image}`',
          @load='imageLoaded'
        )
        canvas#canvas(
          ref='myCanvas',
          @mousedown.left='mouseDown',
          @mouseup.left='mouseUp',
          @mousemove='mouseMove',
          @mousedown.middle='mouseMiddle',
          @mouseout='mouseOut'
        )
    b-col.justify-content-center(sm='auto')
      b-row.justify-content-center
        b-col.justify-content-center
          b-form-checkbox.mt-2.mb-2(v-model='toggleHelp', switch, size='sm') 
            b H:
            |
            | Help
      b-row
        b-col#help.justify-content-center(v-if='toggleHelp', sm='auto')
          b-list-group.p-0(small)
            b-list-group-item T: Toggle BoundingBoxes
            b-list-group-item E: Skip label
            b-list-group-item Q: Showcase
            b-list-group-item SPACE: Skip image
            b-list-group-item ENTER: Confirm
      b-row#help.justify-content-center(v-if='toggleHelp')
        b-card.m-0
          ol.p-0
            li(v-for='(className, classIndex) in cOptions', :key='classIndex') {{ className }}
          ol.p-0
            li(v-for='(sizeName, sizeIndex) in sOptions', :key='sizeIndex') {{ sizeName }}
</template>

<script>
import { getFullDateString } from '../utils/getDate'
import { TOGGLE_HELP, LABELS_GET } from '../store/constants'
import { mapGetters } from 'vuex'
export default {
  props: {
    Source: null,
    isMobile: null,
  },
  components: {},
  data() {
    return this.initialStates()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize)
    window.removeEventListener('keyup', this.keyBinds)
  },

  mounted() {
    window.addEventListener('resize', this.windowResize)
    window.addEventListener('keyup', this.keyBinds)
    this.initialStates()
    this.$store.dispatch(LABELS_GET).then((result) => {
      this.cOptions = result.labels.slice(0, 9)
      this.sOptions = result.sizes.slice(0, 9)
      this.getLabels()
    })
  },
  computed: {
    ...mapGetters(['getToggleHelp']),
  },
  watch: {
    focusElement: function () {
      if (!this.Source.reviewed) {
        this.checkLabels()
      }
    },
    toggleDraw: function () {
      this.DrawRectangleArray()
    },
    focusIndex: function () {
      if (this.ctx) {
        this.getLabels()
        this.DrawRectangleArray()
      }
    },
    Source: function () {
      Object.assign(this.$data, this.resetStates())
      this.createTable()
      this.getLabels()
    },
  },
  methods: {
    initialStates() {
      return {
        labelModel: [],
        sizeModel: [],
        mycanvas: null,
        ctx: null,
        isDown: false,
        startX: null,
        startY: null,
        canvasOffset: null,
        xOffset: null,
        yOffset: null,
        rectHeight: null,
        rectWidth: null,
        BoundingBoxes: [],
        mouseX: null,
        mouseY: null,
        multiplierX: null,
        multiplierY: null,
        loading: true,
        imgContext: null,
        missingLabel: true,
        timeout: null,
        toggleBbox: true,
        toggleHelp: this.getToggleHelp,
        toggleDraw: true,
        newImageRequested: false,
        focusIndex: 0,
        focusIdxClass: [],
        focusIdxSize: [],
        cOptions: [],
        sOptions: [],
        focusElement: 'class',
        imageSkipped: false,
        imageConfirmed: false,
        imageDate: 'Date',
        imageReviewed: false,
        newImage: true,
        table: [],
      }
    },
    resetStates() {
      return {
        labelModel: [],
        sizeModel: [],
        mycanvas: null,
        ctx: null,
        isDown: false,
        startX: null,
        startY: null,
        canvasOffset: null,
        xOffset: null,
        yOffset: null,
        rectHeight: null,
        rectWidth: null,
        BoundingBoxes: [],
        mouseX: null,
        mouseY: null,
        multiplierX: null,
        multiplierY: null,
        loading: true,
        imgContext: null,
        missingLabel: true,
        timeout: null,
        toggleBbox: true,
        toggleDraw: true,
        newImageRequested: false,
        focusIndex: 0,
        focusIdxClass: [],
        focusIdxSize: [],
        focusElement: 'class',
        imageSkipped: false,
        imageConfirmed: false,
        imageDate: 'Date',
        imageReviewed: false,
        newImage: true,
      }
    },
    createTable() {
      var table = []
      this.Source.result.forEach((box) => {
        table.push(box.label)
      })
      this.table = table
    },
    clickLabelChange(label) {
      this.BoundingBoxes[this.focusIndex].label = label
      if (
        this.BoundingBoxes[this.focusIndex].label &&
        this.BoundingBoxes[this.focusIndex].size
      ) {
        this.checkLabels()
        //this.updateImageData(true)
      }
    },
    clickSizeChange(size) {
      this.BoundingBoxes[this.focusIndex].size = size
      if (
        this.BoundingBoxes[this.focusIndex].label &&
        this.BoundingBoxes[this.focusIndex].size
      ) {
        this.checkLabels()
        //this.updateImageData(true)
      }
    },
    getLabels() {
      this.imageReviewed = false
      this.focusIdxClass = []
      this.focusIdxSize = []
      var hasLabel = false
      var hasSize = false
      this.BoundingBoxes.forEach((bbox) => {
        if (this.cOptions.includes(bbox.label)) {
          this.focusIdxClass.push(this.cOptions.indexOf(bbox.label))
          hasLabel = true
        } else {
          this.focusIdxClass.push(-1)
        }

        if (bbox.size && this.sOptions.includes(bbox.size)) {
          this.focusIdxSize.push(this.sOptions.indexOf(bbox.size))
          hasSize = true
        } else {
          this.focusIdxSize.push(-1)
        }
      })
      if (hasLabel && hasSize) {
        this.imageReviewed = true
        this.newImage = false
      }
    },
    setToggleBatch() {
      this.$emit('toggleBatch')
    },
    keyBinds(e) {
      if (e.key == 'ArrowUp') {
        e.preventDefault()
        e.stopPropagation()
        if (this.focusIndex - 1 >= 0) {
          this.focusIndex -= 1
        }
        this.focusElement = 'class'
      }

      if (e.key == 'ArrowDown') {
        e.preventDefault()
        e.stopPropagation()
        if (this.focusIndex + 1 < this.$refs.classes.length) {
          this.focusIndex += 1
        }
        this.focusElement = 'class'
      }

      if (e.key == ' ') {
        e.preventDefault()
        e.stopPropagation()
        if (!this.newImageRequested && !this.loading) {
          this.BoundingBoxes.forEach((box) => {
            box.skip = true
          })
          this.updateImageData(true)
        }
      }
      if (e.key == 'Backspace') {
        e.preventDefault()
        e.stopPropagation()
        this.removeCurrentBox()
      }

      if (e.key == 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        if (!this.newImageRequested && !this.loading) {
          this.BoundingBoxes.forEach((box) => {
            box.skip = false
          })
          //this.BoundingBoxes[this.focusIndex].skip = false
          this.updateImageData(true)
        }
      }

      if (e.key == 'h') {
        //this.toggleHelp = !this.toggleHelp
        this.$store.dispatch(TOGGLE_HELP)
        this.toggleHelp = !this.toggleHelp
      }

      if (e.key == 't') {
        this.toggleDraw = !this.toggleDraw
        this.DrawRectangleArray()
      }

      if (e.key == 'e') {
        this.BoundingBoxes[this.focusIndex].skip =
          !this.BoundingBoxes[this.focusIndex].skip
        this.$forceUpdate()
        if (this.focusIndex == this.BoundingBoxes.length - 1) {
          if (!this.newImageRequested) {
            this.updateImageData(true)
          }
        }
      }

      if (e.key == 'b') {
        this.toggleBatch = !this.toggleBatch
        this.setToggleBatch()
      }
      if (parseInt(e.key)) {
        e.preventDefault()
        e.stopPropagation()
        var key = parseInt(e.key)

        if (
          !this.newImageRequested &&
          !this.loading &&
          this.BoundingBoxes !== null &&
          this.focusIndex !== null
        ) {
          // class select

          if (this.focusElement == 'class') {
            if (key <= this.cOptions.length) {
              this.BoundingBoxes[this.focusIndex].label = this.cOptions[key - 1]
              this.focusElement = 'size'
            }
          } else {
            if (key <= this.sOptions.length) {
              this.BoundingBoxes[this.focusIndex].size = this.sOptions[key - 1]
              this.focusElement = 'class'
            }
          }
        }
        this.$forceUpdate()
        this.getLabels()
        if (!this.Source.reviewed) {
          this.checkLabels()
        }
      }
    },
    keyListenerEvents() {
      window.addEventListener('keyup', this.keyBinds)
    },
    windowResize() {
      if (this.imgContext) {
        this.getImageDims()
        this.DrawRectangleArray()
      }
    },
    removeSelectedBox(rect) {
      this.BoundingBoxes.splice(this.BoundingBoxes.indexOf(rect), 1)
      this.focusIndex = this.focusIndex - 1
      this.DrawRectangleArray()
    },
    getImageDims() {
      if (document.getElementById('image')) {
        this.imgContext = document.getElementById('image')
        this.mycanvas.height = this.imgContext.height
        this.mycanvas.width = this.imgContext.width
        this.multiplierX = this.imgContext.naturalWidth / this.imgContext.width
        this.multiplierY =
          this.imgContext.naturalHeight / this.imgContext.height
      }
    },

    imageLoaded() {
      this.mycanvas = document.getElementById('canvas')
      this.ctx = this.mycanvas.getContext('2d')
      this.getImageDims()
      document.getElementById('image').style.visibility = 'visible'
      if (this.Source.result.length > 0) {
        this.BoundingBoxes = this.Source.result
      }

      this.imageDate = getFullDateString(this.Source.utc_date)

      this.getLabels()

      this.loading = false
      this.focusElement = 'class'
      this.DrawRectangleArray()
    },
    mouseOut(e) {
      e.preventDefault()
      e.stopPropagation()
      this.isDown = false
    },
    mouseDown(e) {
      e.preventDefault()
      e.stopPropagation()

      this.xOffset = this.$refs['myCanvas'].getBoundingClientRect().x
      this.yOffset = this.$refs['myCanvas'].getBoundingClientRect().y
      this.startX = parseInt(e.clientX - this.xOffset)
      this.startY = parseInt(e.clientY - this.yOffset)
      this.rectHeight = null
      this.rectWidth = null
      this.mouseX = null
      this.mouseY = null

      this.isDown = true
    },
    mouseUp(e) {
      e.preventDefault()
      e.stopPropagation()
      this.isDown = false

      if (this.startX && this.startY && this.rectHeight && this.rectWidth) {
        this.BoundingBoxes.push({
          bbox: {
            x: Math.floor(this.startX * this.multiplierX),
            y: Math.floor(this.startY * this.multiplierY),
            w: Math.floor(this.rectWidth * this.multiplierX),
            h: Math.floor(this.rectHeight * this.multiplierY),
          },
          label: 'fish',
        })

        this.focusIndex = this.BoundingBoxes.length - 1
      }
      this.getLabels()
      this.DrawRectangleArray()
    },
    mouseMove(e) {
      e.preventDefault()
      e.stopPropagation()

      if (!this.isDown) {
        this.rectWidth = this.mouseX - this.startX
        this.rectHeight = this.mouseY - this.startY
        return
      }

      this.mouseX = parseInt(e.clientX - this.xOffset)
      this.mouseY = parseInt(e.clientY - this.yOffset)

      this.rectWidth = this.mouseX - this.startX
      this.rectHeight = this.mouseY - this.startY
      this.ctx.strokeStyle = 'red'
      this.ctx.lineWidth = 3
      this.ctx.clearRect(0, 0, this.mycanvas.width, this.mycanvas.height)
      this.ctx.strokeRect(
        this.startX,
        this.startY,
        this.rectWidth,
        this.rectHeight
      )
    },
    mouseMiddle(e) {
      e.preventDefault()
      e.stopPropagation()
      this.removeCurrentBox()
      /*
      this.BoundingBoxes.pop()
      if (!this.BoundingBoxes[this.BoundingBoxes.length - 1].computed) {
        this.BoundingBoxes.pop()
      }
      */
    },
    removeCurrentBox() {
      if (this.BoundingBoxes.length > 0) {
        this.BoundingBoxes.splice(
          this.BoundingBoxes.indexOf(this.BoundingBoxes[this.focusIndex]),
          1
        )
        this.focusIndex = this.focusIndex - 1
        this.DrawRectangleArray()
      }
    },
    checkLabels() {
      this.missingLabel = false
      if (this.BoundingBoxes.length < 1) {
        this.missingLabel = true
      }
      this.BoundingBoxes.forEach((bbox) => {
        if (!bbox.skip) {
          if (bbox.label === '' || bbox.label === null) {
            this.missingLabel = true
          }
          if (!bbox.size || bbox.size == '') {
            this.missingLabel = true
          }
        }
      })
      if (this.missingLabel == false) {
        this.imageConfirmed = true

        this.updateImageData()
      }
    },
    DrawRectangleArray() {
      if (this.ctx) {
        this.ctx.clearRect(0, 0, this.mycanvas.width, this.mycanvas.height)
        if (this.toggleDraw) {
          this.ctx.font = '20px Arial'
          this.ctx.fillStyle = 'yellow'
          this.BoundingBoxes.forEach((bbox, index) => {
            this.DrawRectangle(bbox, index)
          })
        }
      }
    },
    DrawRectangle(result, index) {
      if (this.focusIndex == index) {
        this.ctx.strokeStyle = 'blue'
        this.ctx.lineWidth = 6
      } else {
        this.ctx.strokeStyle = 'red'
        this.ctx.lineWidth = 1
      }

      this.ctx.strokeRect(
        result.bbox.x / this.multiplierX,
        result.bbox.y / this.multiplierY,
        result.bbox.w / this.multiplierX,
        result.bbox.h / this.multiplierY
      )

      this.ctx.fillText(
        result.label,
        result.bbox.x / this.multiplierX,
        result.bbox.y / this.multiplierY
      )
    },
    reqNewImage(direction) {
      this.requestNewImage = true
      this.$emit('nextImage', direction)
    },
    updateImageData() {
      this.loading = true
      var newValue = {
        imageID: this.Source.imageID,
        new: {
          result: this.BoundingBoxes,
          reviewed: true,
        },
      }
      this.$emit('updateParent', newValue)
      this.$emit('nextImage', 'next')
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'list image image image'
    'list image image image'
    'list image image image';
}
.list-group-item {
  padding: 1em;
}
.test {
  border: 1px solid blue;
}
#labels {
  font-size: 0.5em;
}
#help {
  font-size: 0.7em;
}
.image {
  grid-area: image;
}
.list {
  grid-area: list;
}

.b-icon.bi {
  vertical-align: middle;
}

.bbox-skipped {
  background-color: yellow;
  border: solid 3px blue;
}

.remove-icon:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.bbox-selected {
  background-color: lightgray;
  border: solid 3px blue;
}

.input-selected {
  border: 3px solid green;
}

.img-desktop {
  position: absolute;
  z-index: 1;
  height: auto;
  min-height: 25vw;
  max-height: 25vw;
  max-width: 60vw;
  min-width: 60vw;
  visibility: hidden;
}

.img-mobile {
  position: absolute;
  z-index: 1;
  max-height: 100vh;
  max-width: 100%;
  margin: auto;
}

#canvas {
  position: relative;
  z-index: 20;
}
</style>
