<template lang="pug">
b-container(fluid)
  b-card(bg-variant="light")
    h2.mb-3.mt-3 Current Users
    b-row.justify-content-md-center.flex-wrap-reverse(v-if="selectedUser")
      b-overlay(:show="loading")
        b-card(bg-variant="light")
          b-form-group.m-3(label="Pipe Permissions")
            b-form-checkbox-group(v-model="pipePerms" :options="pipes" stacked)
            b-button.mt-3(@click="updateUser" variant="success" size="sm" ) Save
            b-alert.mb-0.mt-2(show v-if="saveComplete") Success
    b-row.mt-3.justify-content-md-center.flex-wrap-reverse
     
      b-table#users.w-auto(
        :fields="fields"
        bordered
        hover 
        :items="userList" 
        selectable  
        select-mode="single"
        striped
        responsive
        @row-clicked="printrow")
      
    b-row.justify-content-md-center.flex-wrap-reverse
      b-button.mt-2.ml-2(size="md" variant="info" @click="getUsers")
        b-icon(icon="arrow-clockwise" scale="1")
      b-form-select.w-auto.ml-2(v-model="tokenPerms" :options="options" required)
      b-button.mt-2.ml-2(@click="generateToken" :disabled="!tokenPerms") Generate Login Token
      b-overlay(:show="token_loading")
        b-form-input.w-auto.ml-2.mr-2.mt-2(v-model="genToken")
      b-button.mt-2.ml-2(v-if="timer > 0" variant="warning") {{this.timer}}
    b-row.justify-content-md-center.flex-wrap-reverse
      b-card.mt-3(bg-variant="light")
        h3 User page control
        p 
          b Customer:
          |  Statistics, Report
        p 
          b User:
          |  Statistics, Report, Preview, Streams
        p 
          b Moderator: 
          |  Statistics, Report, Preview, Streams, Images



</template>

<script>
import {
  GEN_TOKEN,
  GET_USERS,
  GET_PIPES,
  UPDATE_PERMS,
} from '../store/constants'
export default {
  data() {
    return {
      fields: [
        { key: 'email', sortable: true },
        { key: 'status', sortable: true },
        { key: 'permissions', sortable: true },
        { key: 'images_reviewed', sortable: true },
      ],
      loading: false,
      token_loading: false,
      userList: [],
      genToken: null,
      timer: 0,
      selectedUser: null,
      pipePerms: [],
      tokenPerms: null,
      options: [
        { value: 'customer', text: 'customer' },
        { value: 'user', text: 'user' },
        { value: 'moderator', text: 'moderator' },
        { value: 'admin', text: 'admin' },
      ],

      pipes: [],
      saveComplete: false,
    }
  },
  mounted() {
    this.genToken = null
    this.getUsers()
    this.getSources()
  },
  methods: {
    getSources() {
      this.$store.dispatch(GET_PIPES).then((result) => {
        this.pipes = []
        result.forEach((item) => {
          var value = item.split(' ')
          this.pipes.push({ text: item, value: value[0] })
        })
      })
    },
    updateUser() {
      const { selectedUser, pipePerms } = this
      pipePerms.forEach((pipe, index) => {
        pipePerms[index] = pipe.split(' ')[0]
      })

      this.$store
        .dispatch(UPDATE_PERMS, { selectedUser, pipePerms })
        .then((result) => {
          this.saveComplete = result
          this.getUsers()
          setTimeout(() => {
            this.saveComplete = false
          }, 4000)
        })
    },
    printrow(item) {
      this.selectedUser = item
      this.pipePerms = item.permissions
    },
    generateToken() {
      this.genToken = null
      this.token_loading = true

      this.$store.dispatch(GEN_TOKEN, this.tokenPerms).then((result) => {
        if (result.token && result.token != '') {
          this.token_loading = false
          this.genToken = result.token
          this.timer = 20
          var countDown = setInterval(() => {
            this.timer--
          }, 1000)
          setTimeout(() => {
            this.genToken = ''
            this.tokenPerms = null
            clearInterval(countDown)
          }, 21000)
        }
      })
    },
    getUsers() {
      this.$store.dispatch(GET_USERS).then((result) => {
        this.userList = result
      })
    },
  },
}
</script>

<style scoped></style>
