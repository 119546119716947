<template lang="pug">
b-container(fluid)
  b-alert.mt-2.w-50.m-auto(v-if="cameras.length == 0 && !loading" show)  No live streams online!
  b-card.mt-3.w-auto(bg-variant="" v-if="cameras.length > 0")
    h2.mb-3 Cameras
    b-row.justify-content-center.flex-wrap-reverse
      b-list-group.w-auto
        b-list-group-item(v-for='(url, index) in cameras' :key="index" :href="url.url" target="_blank" variant="secondary") {{url.name}}

    


</template>

<script>
import { CAMERA_GET } from '../store/constants'
export default {
  name: 'Feed',
  data() {
    return {
      cameras: [],
      loading: false,
    }
  },
  computed: {
    getCurrentLocation() {
      return this.$store.getters.getCurrentLocation
    },
  },
  watch: {
    getCurrentLocation: function() {
      this.getCameras()
    },
  },
  methods: {
    getCameras() {
      this.loading = true
      var pipe = this.$store.getters.getCurrentLocation
      this.$store.dispatch(CAMERA_GET, pipe).then((result) => {
        this.cameras = result
        setTimeout(() => {
          this.loading = false
        }, 700)
      })
    },
  },
  mounted() {
    this.getCameras()
  },
}
</script>

<style></style>
