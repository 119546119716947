function parse_cookies(cookies) {
  if (cookies) {
    cookies = cookies.replace(/\s/g, '')
    var cookieList = {}
    var cookieString = cookies.split(';')
    cookieString.forEach((line) => {
      var splitted = line.split('=')
      cookieList[splitted[0]] = splitted[1]
    })
    return cookieList
  } else {
    return false
  }
}

export default parse_cookies
