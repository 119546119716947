<template lang="pug">
div
  b-overlay(:show='imagesLoading && pipeSelected != null')
    b-container.mb-5(fluid)
      h1.mt-3 {{ locationSelected }}
      b-row.justify-content-center
        b-alert(
          v-if='imageList.length == 0 && !imagesLoading',
          variant='info',
          show
        )
          | No new images.
      b-row.justify-content-center
        b-list-group.p-0(horizontal='sm', small)
          b-list-group-item.m-0.p-1
            b-form-select.w-auto(v-model='pipeSelected', :options='userPipes')
          b-list-group-item.m-0.p-1(v-if='pipeSelected != null')
            b-button.m-1(
              @click='asc',
              size='sm',
              :variant='sort == 1 ? "success" : ""'
            ) Oldest
          b-list-group-item.m-0.p-1(v-if='pipeSelected != null')
            b-button.m-1(
              @click='desc',
              size='sm',
              :variant='sort == -1 ? "success" : ""'
            ) Newest
          b-list-group-item.m-0.p-1(v-if='imageList.length > 0')
            b-button.m-1(size='sm', variant='light', disabled) ({{ totalIndex + 1 }} / {{ imageList.length }}) / estimated: {{ totalImageCount }}
      b-row.justify-content-center
        boundBox#bbox-container(
          v-if='boundBoxImageUrl',
          :groupLabel='label',
          :Source='boundBoxImageUrl',
          :isMobile='isMobile',
          @updateParent='updateTest',
          @nextImage='setNextImage',
          @toggleBatch='setToggleBatch'
        )

      b-row.justify-content-center
        b-col(sm='auto')
          h5(v-if='sort == 1')
            b-badge.mt-5(
              v-if='imageList.length > 0 && currentImageIndex != 0 && !imagesLoading',
              variant='danger'
            ) -{{ timeDiffLast }}
          h5(v-if='sort == -1')
            b-badge.mt-5(
              v-if='imageList.length > 0 && currentImageIndex != 0 && !imagesLoading',
              variant='success'
            ) +{{ timeDiffLast }}
        b-col(sm='auto')
          b-icon.mt-5.pt-1.nav-icon(
            v-if='currentImageIndex != 0',
            icon='arrow-left-circle-fill',
            scale='4',
            @click='navigateImages("prev")'
          )
        b-col(sm='auto')
          b-container.thumbnail-list.mt-3(
            fluid='xl',
            draggable='false',
            v-if='imageList.length > 0 && batchView'
          ) 
            div(
              v-for='(img, index) in imageListShow',
              :key='index',
              @click='imageClicked(img, index)'
            )
              b-img.img-thumbnail(
                :src='`data:image/png;base64,${img.thumbnail}`',
                thumbnail,
                draggable='false',
                :class='currentImageIndex == index ? "image-selected" : ""'
              )
              b-icon.icon(
                icon='check',
                variant='success',
                scale='4',
                shift-v='-10',
                shift-h='-70',
                v-if='img.reviewed && !img.skip'
              )
              b-icon.icon(
                icon='x',
                variant='primary',
                scale='4',
                shift-v='-10',
                shift-h='-70',
                v-if='img.skip'
              )
              b-icon.icon(
                icon='star-fill',
                variant='warning',
                scale='3',
                shift-v='-70',
                shift-h='-70',
                v-if='img.show'
              )

        b-col(sm='auto')
          b-icon#next.mt-5.pt-1.nav-icon(
            v-if='currentImageIndex != imageList.length - 1 && imageList.length > 0',
            icon='arrow-right-circle-fill',
            scale='4',
            @click='navigateImages("next")'
          )
        b-col(sm='auto')
          h5(v-if='sort == -1')
            b-badge.mt-5(
              v-if='imageList.length > 0 && !imagesLoading',
              variant='danger'
            ) -{{ timeDiffNext }}
          h5(v-if='sort == 1')
            b-badge.mt-5(
              v-if='imageList.length > 0 && !imagesLoading',
              variant='success'
            ) +{{ timeDiffNext }}
</template>

<script>
import boundBox from '../components/bound'
import {
  POST_SAMPLE,
  GET_IMAGES,
  UPDATE_SHOWCASE,
  GET_TOTAL_IMAGES,
} from '@/store/constants'
import { GET_CURRENT_USER_PERMS } from '../store/constants'

export default {
  components: { boundBox },
  data() {
    return {
      imageListShow: [],
      imageList: [],
      loading: false,
      rating: null,
      label: null,
      boundBoxImageUrl: null,
      isMobile: null,
      batchView: true,
      currentImageIndex: 0,
      slideIndex: 0,
      totalIndex: 0,
      timeDiffLast: 0,
      timeDiffNext: 0,
      imagesLoading: true,
      imageShowCase: null,
      totalImageCount: 0,
      sort: 1,
      locationSelected: null,
      pipeSelected: null,
      userPipes: [],
    }
  },
  computed: {
    getCurrentLocation() {
      return this.$store.getters.getCurrentLocation
    },
  },
  watch: {
    getCurrentLocation: function (newValue) {
      this.locationSelected = newValue
    },
    pipeSelected: function () {
      this.getImages()
    },
  },
  mounted() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    this.locationSelected = this.$store.getters.getCurrentLocation
    this.$store.dispatch(GET_CURRENT_USER_PERMS).then((result) => {
      this.userPipes = []
      result.forEach((item) => {
        var temp = item.split(' ')
        if (temp[1] == this.locationSelected) {
          this.userPipes.push(item)
        }
      })
    })
    if (this.pipeSelected) {
      this.getImages()
    }
    this.keyListenerEvents()
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyBinds)
  },
  methods: {
    asc() {
      this.sort = 1
      this.getImages()
      document.activeElement.blur()
    },
    desc() {
      this.sort = -1
      this.getImages()
      document.activeElement.blur()
    },
    keyListenerEvents() {
      window.addEventListener('keydown', this.keyBinds)
    },
    keyBinds(e) {
      if (!parseInt(e.key)) {
        if (e.key === 'ArrowDown') {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.key === 'ArrowRight') {
          e.preventDefault()
          e.stopPropagation()
          this.navigateImages('next')
        }
        if (e.key === 'ArrowLeft') {
          e.preventDefault()
          e.stopPropagation()
          this.navigateImages('prev')
        }
        if (e.key === 'q') {
          this.updateShowCase()
        }
      }
    },
    updateShowCase() {
      this.$store
        .dispatch(
          UPDATE_SHOWCASE,
          this.imageListShow[this.currentImageIndex].imageID
        )
        .then((result) => {
          this.imageListShow[this.currentImageIndex].show = result
          this.$forceUpdate()
        })
    },
    setToggleBatch() {
      this.batchView = !this.batchView
    },
    setNextImage(value) {
      setTimeout(() => {
        this.navigateImages(value)
      }, 100)
    },
    msToTime(milliseconds) {
      var minutes = Math.floor(milliseconds / 60000)
      var seconds = ((milliseconds % 60000) / 1000).toFixed(0)
      return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
    },
    imageClicked(img, index) {
      if (this.slideIndex == 0 && index >= 5) {
        this.slideIndex += index - 4
        this.totalIndex = this.slideIndex + 4
      } else if (this.slideIndex >= 0 && index >= 5) {
        this.slideIndex += index - 4
        this.totalIndex = this.slideIndex + index
        this.currentImageIndex = 4
      } else if (this.slideIndex > 0 && index < 4) {
        this.slideIndex -= 4 - index
        this.totalIndex = this.slideIndex + index
        this.currentImageIndex = 4
      } else if (this.slideIndex <= 0 && index <= 4) {
        this.slideIndex = 0
        this.totalIndex = index
        this.currentImageIndex = index
      }
      this.imageListShow = this.imageList.slice(
        this.slideIndex,
        this.slideIndex + 9
      )
      if (this.totalIndex >= this.imageList.length + 1) {
        this.getImages()
      } else {
        this.setImageToBounding(
          this.imageListShow[this.currentImageIndex],
          this.currentImageIndex
        )
      }
    },
    navigateImages(direction) {
      switch (direction) {
        case 'next':
          if (this.totalIndex + 1 <= this.imageList.length - 1) {
            if (this.imageList.length > 9) {
              if (this.currentImageIndex >= this.imageListShow.length / 2 - 1) {
                this.slideIndex++
                this.imageListShow = this.imageList.slice(
                  this.slideIndex,
                  this.slideIndex + 9
                )
              } else {
                this.currentImageIndex++
              }
              this.totalIndex++
            } else {
              this.currentImageIndex++
              this.totalIndex++
            }
          } else {
            if (!this.imagesLoading) {
              this.getImages()
            }
          }

          break
        case 'prev':
          if (this.currentImageIndex - 1 >= 0) {
            if (this.imageList.length > 9) {
              if (
                this.currentImageIndex >= this.imageListShow.length / 2 - 1 &&
                this.slideIndex > 0
              ) {
                this.slideIndex--
                this.imageListShow = this.imageList.slice(
                  this.slideIndex,
                  this.slideIndex + 9
                )
              } else {
                this.currentImageIndex--
              }
              this.totalIndex--
            } else {
              this.currentImageIndex--
              this.totalIndex--
            }
          }
          break
      }
      this.setImageToBounding(
        this.imageListShow[this.currentImageIndex],
        this.currentImageIndex
      )
    },
    updateTest(value) {
      if (value) {
        this.imageList.forEach((image) => {
          if (image.imageID === value.imageID) {
            image.result = value.new.result
            image.reviewed = value.new.reviewed
            image.skip = value.skip
            //append to value
            value.date = image.date
            value.pipe = image.source
            value.filename = image.filename
            this.$store.dispatch(POST_SAMPLE, value)
          }
        })
      }
    },
    setImageToBounding(img, index) {
      this.boundBoxImageUrl = img
      this.currentImageIndex = index
      //window.scrollTo(0, 0)
      // time difference to previous
      if (this.currentImageIndex > 0) {
        var datePrev = new Date(
          this.imageListShow[this.currentImageIndex - 1].date
        )
        dateCurr = new Date(this.imageListShow[this.currentImageIndex].date)
        if (this.sort == 1) {
          this.timeDiffLast = this.msToTime(dateCurr - datePrev)
        } else {
          this.timeDiffLast = this.msToTime(datePrev - dateCurr)
        }
      } else {
        this.timeDiffLast = 0
      }

      // time difference to next
      if (this.totalIndex < this.imageList.length - 1) {
        var dateNext = new Date(
          this.imageListShow[this.currentImageIndex + 1].date
        )
        var dateCurr = new Date(this.imageListShow[this.currentImageIndex].date)

        if (this.sort == 1) {
          this.timeDiffNext = this.msToTime(dateNext - dateCurr)
        } else {
          this.timeDiffNext = this.msToTime(dateCurr - dateNext)
        }
      } else {
        this.timeDiffNext = 0
      }
    },
    getImages() {
      var userPipePermissions = this.$store.getters.getPipePerms
      var pipeID = []
      userPipePermissions.forEach((element) => {
        var pipeString = element.split(' ')
        if (pipeString[1] == this.pipeSelected) pipeID.push(pipeString[0])
      })

      //console.log(this.pipeSelected)

      this.imagesLoading = true
      this.loading = true
      this.imageList = []
      this.boundBoxImageUrl = null

      this.$store
        .dispatch(GET_IMAGES, {
          sort: this.sort,
          id: this.pipeSelected.split(' ')[0],
          source: this.locationSelected,
        })
        .then((result) => {
          this.imageList = result
          this.currentImageIndex = 0
          this.slideIndex = 0
          this.totalIndex = 0
          this.imageListShow = result.slice(0, 9)
          this.setImageToBounding(this.imageListShow[this.currentImageIndex], 0)
          this.imagesLoading = false
        })
      this.$store
        .dispatch(GET_TOTAL_IMAGES, {
          id: this.pipeSelected.split(' ')[0],
          source: this.locationSelected,
        })
        .then((result) => {
          this.totalImageCount = result
        })
    },
  },
}
</script>

<style scoped lang="stylus">
.image-selected {
  transform: scale(1.1);
  transition: 0.5s;
  z-index: 1;
  border: 6px solid green;
}

.nav-icon:hover {
  transition: 0.2s;
  cursor: pointer;
}

#group-label {
  width: 100px;
}

.reviewed {
  height: 50px im !important;
  width: 50px;
}

.thumbnail-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: center;
}

.img-thumbnail {
  position: relative;
  height: 100px;
  width: 120px;
  padding: 0px;
  overflow: scroll;
}

.img-thumbnail:hover {
  position: relative;
  transform: scale(1.1);
  transition: 0.1s;
  z-index: 1;
}

.icon {
  float: left;
  position: absolute;
  z-index: 2;
}

.list-group-item {
  border: none;
}

.sticky {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
