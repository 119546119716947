import Vue from 'vue'
import VueRouter from 'vue-router'
import Stats from '@/views/stats.vue'
import Login from '@/views/login.vue'
import Register from '@/views/register.vue'
import Images from '@/views/images.vue'
import Images2 from '@/views/images2.vue'
import Control from '@/views/user-control.vue'
import Cameras from '@/views/cameras.vue'
import Labels from '@/views/labels.vue'
import Chat from '@/views/chat.vue'
import User from '@/views/user.vue'
import Api from '@/views/api.vue'
import Report from '@/views/report.vue'
import Feed from '@/views/streams.vue'
import Settings from '@/views/app-settings.vue'
import Preview from '@/views/preview.vue'
import Debug from '@/views/debug.vue'
import Home from '@/views/home.vue'
import Locations from '@/views/locations.vue'
import store from '../store'

import { AUTH } from '../store/constants'

function isAuthorized(to, from, next) {
  store.dispatch(AUTH, to.name).then((res) => {
    if (res) {
      next()
    } else {
      next({ name: 'Login' })
    }
  })
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/locations',
    name: 'Home',
    component: Home,
    beforeEnter: isAuthorized,
  },
  {
    path: '/locations/rename',
    name: 'Locations',
    component: Locations,
    beforeEnter: isAuthorized,
  },
  {
    path: '/statistics',
    name: 'Stats',
    component: Stats,
    beforeEnter: isAuthorized,
  },
  {
    path: '/debug',
    name: 'Debug',
    component: Debug,
    beforeEnter: isAuthorized,
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    beforeEnter: isAuthorized,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    beforeEnter: isAuthorized,
  },
  {
    path: '/preview',
    name: 'Preview',
    component: Preview,
    beforeEnter: isAuthorized,
  },
  {
    path: '/api',
    name: 'Api',
    component: Api,
    beforeEnter: isAuthorized,
  },
  {
    path: '/labels',
    name: 'Labels',
    component: Labels,
    beforeEnter: isAuthorized,
  },
  {
    path: '/cameras',
    name: 'Cameras',
    component: Cameras,
    beforeEnter: isAuthorized,
  },
  {
    path: '/user/control',
    name: 'Control',
    component: Control,
    beforeEnter: isAuthorized,
  },
  {
    path: '/reports',
    name: 'Report',
    component: Report,
    beforeEnter: isAuthorized,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/images',
    name: 'Images',
    component: Images,
    beforeEnter: isAuthorized,
  },
  {
    path: '/images2',
    name: 'Images2',
    component: Images2,
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    beforeEnter: isAuthorized,
  },
  {
    path: '/live',
    name: 'Feed',
    component: Feed,
    beforeEnter: isAuthorized,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
