<template lang="pug">
b-container(fluid)
  b-card(bg-variant="light" title="Location naming")
    b-row(v-for="(pipe,index) in pipes" :key="index").justify-content-center
      p.myclass ID: {{pipe.name}}
      b-form-input.w-50.ml-2(v-model="pipe.tag" placeholder="tag")
    b-row.justify-content-center.mt-3
      b-button(@click="onSubmit") Save

</template>

<script>
import { GET_SOURCE_TAGS, UPDATE_SOURCE_TAGS } from '../store/constants'
export default {
  data() {
    return {
      pipes: [],
    }
  },
  mounted() {
    this.getSources()
  },
  methods: {
    getSources() {
      this.$store.dispatch(GET_SOURCE_TAGS).then((result) => {
        this.pipes = result
      })
    },
    onSubmit() {
      this.$store.dispatch(UPDATE_SOURCE_TAGS, this.pipes).then((result) => {
        this.pipes = result
      })
    },
  },
}
</script>

<style scoped>
p.myclass {
  font: bold 15px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 10px;
  height: 30px;
  margin: 0px;
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
}
</style>
