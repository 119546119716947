
<template lang="pug">
.banner
  a(href='https://fishheart.com')
    img(src='../assets/banner.png', fluid, alt='#')
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style lang="stylus">
.banner {
  width: auto;
  background-color: #f9f3ee;
}

.banner img {
  height: 100%;
  width: auto;
}
</style>
