import URI from '../config/api'
import parse_cookies from './cookieparser'
import store from '../store'
import { AUTH_SUCCESS, LOGOUT } from '../store/constants'

const tokenStatus = () => {
  var cookies = parse_cookies(document.cookie)

  if (cookies['{token}']) {
    return true
  } else {
    return false
  }
}

const fetchAccessToken = async () => {
  if (sessionStorage.getItem('token')) {
    var response = await fetch(URI + '/auth/refresh', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
    if (response.ok) {
      store.dispatch(AUTH_SUCCESS, await response.json())
      return response.ok
    } else {
      store.dispatch(LOGOUT)
      return false
    }
  } else {
    store.dispatch(LOGOUT)
    return false
  }
}

/**
 *
 * @param {*} Method
 * @param {*} Path
 * @param {*} ContentType
 * @param {*} Headers
 * @param {*} Body
 * @returns {*} Response
 */
const ApiQuery = async (
  Method,
  Path,
  Headers = {},
  Body = {},
  ContentType = 'application/json'
) => {
  var default_headers = {
    //authorization: `Bearer ${sessionStorage.getItem('token')}`,
    'Content-Type': ContentType,
  }
  var keys = Object.keys(Headers)

  if (keys.length > 0) {
    keys.forEach((key) => {
      default_headers[key] = Headers[key]
    })
  }

  var query = {
    method: Method,
    credentials: 'include',
    headers: default_headers,
    body: JSON.stringify(Body),
  }
  //console.log(query)
  if (['GET', 'HEAD'].includes(Method.toUpperCase())) delete query.body

  if (!(await tokenStatus())) {
    if (await fetchAccessToken()) {
      return await fetch(URI + Path, query)
    } else {
      return false
    }
  } else {
    return await fetch(URI + Path, query)
  }
}

export default ApiQuery
