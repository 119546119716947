<template lang="pug">
b-container(fluid)
  b-card(bg-variant="light")
    b-row.justify-content-center.flex-wrap-reverse
      b-form-textarea.w-25(
        rows="1"
        max-rows=6
        maxlength=1000
        v-model="message"
        placeholder="Enter something.."
        )
    b-row.justify-content-center.flex-wrap-reverse
      b-button.mt-2(@click="sendMsg" variant="info") Send
    b-row.justify-content-center.flex-wrap-reverse.mt-4
      b-col(sm="auto").justify-content-center
        b-list-group
          b-list-group-item.d-flex.w-75(v-for="(msg,index) in allMessages" :key="index")
            div#message
              p.m-0
                b.mt-0 {{msg.email}}
                span.m-0.ml-3 {{getDate(msg.date)}}
            
              pre.wrap.mt-2.mb-0 {{msg.message}}
          

</template>

<script>
import { SEND_MSG, GET_MSG } from '../store/constants'
import { getFullDateString } from '../utils/getDate'
export default {
  data() {
    return {
      timer: null,
      message: '',
      allMessages: [],
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    this.getMsg()
    this.timer = setInterval(() => {
      this.getMsg()
    }, 5000)
  },
  methods: {
    getDate(date) {
      return getFullDateString(date)
    },
    sendMsg() {
      var newMessage = {
        message: this.message,
      }
      this.$store.dispatch(SEND_MSG, newMessage).then((result) => {
        this.allMessages = result
        this.message = ''
      })
    },
    getMsg() {
      this.$store.dispatch(GET_MSG).then((result) => {
        this.allMessages = result
      })
    },
  },
}
</script>

<style scoped>
.list-group-item {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 75%;
}

.wrap {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

#message {
  width: 100%;
}
</style>
