<template lang="pug">
b-container(fluid)
  b-card(bg-variant="light")
    h2.mb-3 Statistics settings
    b-row.justify-content-center.flex-wrap-reverse.mt-5
      p Default statistics date range is (current year)-01-01 to (current year+1)-01-01
    b-row.justify-content-center.flex-wrap-reverse
      b-col(sm="auto")
        label(for="from") Start date
        b-input-group
          b-form-input.w-auto(
            v-model="from"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
            size="sm"
            @update="test"
            )
          b-form-datepicker(
            id="from"
            v-model="from"
            size="sm"
            @input="updateLabels"
            button-only)
        label.mt-3(for="to") End date
        b-input-group
          b-form-input.w-auto(
            v-model="to"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
            size="sm"
            @update="test"
            )
          b-form-datepicker(
            id="to"
            v-model="to"
            size="sm"
            @input="updateLabels"
            button-only)
    b-row.justify-content-center.flex-wrap-reverse.mt-5
      b-col(sm="auto")
        b-form-group(label="Select migratory labels for statistics.")
          b-form-checkbox-group(
            v-model="migratory_labels"
            :options="labels.labels"
            @change="updateLabels")

</template>

<script>
import { LABELS_GET, UPDATE_STAT_LABELS } from '../store/constants'
export default {
  data() {
    return {
      labels: [],
      migratory_labels: [],
      from: null,
      to: null,
      timer: null,
    }
  },
  mounted() {
    this.getLabels()
  },

  methods: {
    test() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.from = this.dateString(this.from)
        this.to = this.dateString(this.to)
        this.updateLabels()
        this.timer = null
      }, 2000)
    },
    updateLabels() {
      const { migratory_labels, from, to } = this
      this.$store
        .dispatch(UPDATE_STAT_LABELS, { migratory_labels, from, to })
        .then((result) => {
          this.labels = result
          this.migratory_labels = result.migratory_labels
          this.from = this.dateString(result.from)
          this.to = this.dateString(result.to)
        })
      this.$forceUpdate()
    },
    dateString(date) {
      if (date) {
        var d = new Date(date)
        var day = d.getDate()
        var month = d.getMonth() + 1
        var year = d.getFullYear()
        if (month.toString().length < 2) month = '0' + month
        if (day.toString().length < 2) day = '0' + day
        return year + '-' + month + '-' + day
      } else {
        return null
      }
    },
    getLabels() {
      this.$store.dispatch(LABELS_GET).then((result) => {
        this.labels = result
        this.migratory_labels = result.migratory_labels
        this.from = this.dateString(result.from)
        this.to = this.dateString(result.to)
        this.$forceUpdate()
      })
    },
  },
}
</script>

<style scoped>
.remove-icon:hover {
  transform: scale(1.4);
  cursor: pointer;
}
</style>
