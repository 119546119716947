<script>
import { Line } from 'vue-chartjs'
import { GET_YEAR_DATA } from '@/store/constants'
export default {
  extends: Line,
  props: {
    Pipe: String,
  },
  methods: {
    updateChart() {},
    getCurrentStats() {
      this.$store.dispatch(GET_YEAR_DATA, this.Pipe).then((result) => {
        var label = []
        var data = []

        result.forEach((element) => {
          label.push(
            `${element._id.day}/${element._id.month}/${element._id.year}`
          )
          data.push(element.count)
        })
        data.push(0)
        this.renderChart(
          {
            labels: label,
            datasets: [
              {
                label: 'Total',
                backgroundColor: 'transparent',

                borderColor: 'rgba(1, 1, 255, 0.50)',
                pointBackgroundColor: 'rgba(255, 1,1, 1)',
                data: data,
              },
            ],
          },
          { responsive: true, maintainAspectRatio: false }
        )
      })
    },
  },

  mounted() {
    this.getCurrentStats()
  },
}
</script>
