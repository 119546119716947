import {
  TOGGLE_HELP,
  TOGGLE_DRAW,
  GEN_TOKEN,
  GET_USERS,
  UPDATE_USER,
  UPDATE_FAIL,
  UPDATE_PERMS,
  GET_REPORT_PERMS,
  GET_CURRENT_USER_PERMS,
  LOADING_DONE,
  UPDATE_C_U_PERMS,
  PIPE_SELECTION_CHANGE,
  UPDATE_PIPE_SELECTION,
  LOGOUT,
  RESET_PERMS,
} from '../constants'
import ApiQuery from '../../utils/apiHandler'

const state = {
  toggleHelp: false,
  toggleDraw: true,
  userPipePermissions: [],
  userPipeNames: [],
  userPipeSelected: null,
}

const getters = {
  getToggleHelp: (state) => state.toggleHelp,
  getToggleDraw: (state) => state.toggleDraw,
  getPipeNames: (state) => state.userPipeNames,
  getPipePerms: (state) => state.userPipePermissions,
  getCurrentLocation: (state) => state.userPipeSelected,
}

const actions = {
  [TOGGLE_HELP]: ({ commit }) => commit(TOGGLE_HELP),
  [TOGGLE_DRAW]: ({ commit }) => commit(TOGGLE_DRAW),

  [GET_REPORT_PERMS]: async ({ commit }) => {
    try {
      var response = await ApiQuery('GET', '/user/report/perms', {})
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_CURRENT_USER_PERMS]: async ({ commit, dispatch }) => {
    try {
      var response = await ApiQuery('GET', '/user/perms')
      if (response.ok) {
        var json = await response.json()
        commit(UPDATE_C_U_PERMS, json)
        return json
      } else {
        dispatch(LOGOUT)
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },

  [UPDATE_PERMS]: async ({ commit }, perms) => {
    try {
      var response = await ApiQuery('POST', '/user/update/perm', {}, perms)
      if (response.ok) {
        commit(LOADING_DONE)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GEN_TOKEN]: async ({ commit }, perms) => {
    try {
      var response = await ApiQuery('GET', '/auth/generate', {
        Permission: perms,
      })
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },

  [GET_USERS]: async ({ commit }) => {
    try {
      var response = await ApiQuery('GET', '/user/list')
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [UPDATE_USER]: async ({ commit }, user) => {
    try {
      var response = await ApiQuery('POST', '/user/update', {}, { user })
      if (response.ok) {
        commit(LOADING_DONE)
        return true
      } else {
        commit(UPDATE_FAIL, await response.json())
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [PIPE_SELECTION_CHANGE]: async ({ commit }, value) => {
    commit(UPDATE_PIPE_SELECTION, value)
  },
  [RESET_PERMS]: ({ commit }) => {
    commit(RESET_PERMS)
  },
}

const mutations = {
  [RESET_PERMS]: (state) => {
    state.userPipePermissions = []
    state.userPipeNames = []
    state.userPipeSelected = null
  },
  [TOGGLE_HELP]: (state) => {
    state.toggleHelp = !state.toggleHelp
  },
  [TOGGLE_DRAW]: (state) => {
    state.toggleDraw = !state.toggleDraw
  },
  [UPDATE_C_U_PERMS]: (state, perms) => {
    var combined = []
    perms.forEach((element) => {
      var pipe = element.split(' ')
      if (!combined.includes(pipe[1])) combined.push(pipe[1])
    })
    state.userPipePermissions = perms
    state.userPipeNames = combined
  },
  [UPDATE_PIPE_SELECTION]: (state, value) => {
    state.userPipeSelected = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
