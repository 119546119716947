<template lang="pug">
div.formi
  b-overlay(:show="auth")
    b-card(bg-variant="light")
        h2 Login 
        b-form(@submit.prevent="onSubmit",  @reset.prevent="onReset")
            b-form-group(label="Email")
                b-form-input(
                    size="sm"
                    v-model="email",
                    type="email",
                    placeholder="Email",
                    required)
            br
            b-form-group(label="Password")
                b-form-input(
                size="sm"
                v-model="password",
                type="password",
                placeholder="Password",
                required)
            p(v-for="error in errors" :key="error" class="error-msg") {{error}}
            b-form-group
                b-row
                    b-col
                      b-button(type="submit" variant="primary") Login
                    b-col
                      b-button(type="reset" variant="danger") Reset
            
        
        
</template>
<script>
import { LOGIN, RESET } from '../store/constants'

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      auth: false,
    }
  },
  computed: {
    errors() {
      return this.$store.getters.errors
    },
  },
  mounted() {
    this.$store.dispatch(RESET)
  },
  methods: {
    onSubmit() {
      this.auth = true
      const { email, password } = this
      this.$store.dispatch(LOGIN, { email, password }).then((res) => {
        if (res.ok) {
          setTimeout(() => {
            this.auth = false
            this.$router.push('/locations')
          }, 500)
        } else {
          setTimeout(() => {
            this.auth = false
          }, 500)
        }
      })
    },
    onReset() {
      this.email = ''
      this.password = ''
      this.$store.dispatch(RESET)
    },
  },
}
</script>

<style lang="stylus" scoped>
.error-msg
  color red

.formi
    display: flex
    align-items: top 
    justify-content: center
    padding-top: 3%;
</style>
