<template lang="pug">
.mb-5
  h1.mt-3 {{ currentPipe }}
  b-container(v-if='currentPipe', fluid, bg-variant='light')
    .mt-4.d-flex.justify-content-center
      b-carousel#carousel-1.w-50.h-50(
        v-if='showCase.length > 0',
        v-model='slide',
        :interval='4000',
        controls,
        background='#000000',
        indicators,
        @sliding-start='onSlideStart',
        @sliding-end='onSlideEnd'
      )
        b-carousel-slide(
          v-for='(image, index) in showCase',
          :key='index',
          :img-src='`data:image/png;base64,${image.image}`'
        )
      b-spinner(v-if='loading', label='Loading...')
      b-alert.mt-3(
        v-if='showCase.length == 0 && !loading',
        variant='info',
        show
      )
        | Sorry, no showcased images!
    b-row.justify-content-center
      b-col
        p.mt-3.mb-0
          // b Species
    b-row.justify-content-center
      b-col
        // b-form-select.w-auto( v-model="selected" :options="labels" size="sm")
    b-row.justify-content-center.mt-3(v-if='currentPipe')
      b Total: {{ yearTotal }}
    b-row.justify-content-center
      BarTotal.w-50(:Pipe='currentPipe')
    //- b-row.justify-content-center.mt-4(v-if="cameras.length > 0").mb-5
    //-   b-col
    //-     b-alert.mt-2(v-if="cameras.length == 0 && !loading" show)  No live streams online!
    //-     b-row.justify-content-center
    //-       h2.mb-3 Cameras
    //-     b-row.justify-content-center.flex-wrap-reverse
    //-       b-list-group.w-auto
    //-         b-list-group-item(v-for='(url, index) in cameras' :key="index" :href="url.url" target="_blank" variant="dark") {{url.name}}
</template>

<script>
import BarMigratory from '../charts/CurrentDailyChart.vue'
import BarTotal from '../charts/TotalChart.vue'
import { GET_STATS } from '../store/constants'
export default {
  name: 'Stats',
  data() {
    return {
      slide: 0,
      sliding: null,
      showCase: [],
      stats: [],
      labels: [],
      selected: null,
      yearTotal: null,
      yearMigTotal: null,
      currentPipe: this.getPipeSelection,
      cameras: [],
      loading: false,
    }
  },
  components: {
    BarMigratory,
    BarTotal,
  },
  methods: {
    getStats() {
      if (this.currentPipe) {
        this.loading = true
        this.$store.dispatch(GET_STATS, this.currentPipe).then((result) => {
          this.labels = result.labels
          if (this.labels.includes('fish')) {
            this.labels.splice(this.labels.indexOf('fish'), 1)
          }
          if (this.labels.includes('Negative')) {
            this.labels.splice(this.labels.indexOf('Negative'), 1)
          }
          console.log(result)
          this.yearTotal = result.total
          this.yearMigTotal = result.migratory
          this.showCase = result.showcase
          this.loading = false
        })
      }
    },

    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
      if (this.slide == this.showCase.length) {
        this.getShowCase()
      }
    },
    getPipe() {
      this.currentPipe = this.$store.getters.getCurrentLocation
    },
    // getCameras() {
    //   this.$store.dispatch(CAMERA_GET, this.currentPipe).then((result) => {
    //     this.cameras = result
    //   })
    // },
  },
  mounted() {
    this.getPipe()
    this.getStats()
    // this.getCameras()
  },
  computed: {
    getUserName() {
      var userInfo = this.$store.getters.getUser
      if (userInfo !== null) {
        return userInfo.username
      }
      return null
    },
    getPipeSelection() {
      return this.$store.getters.getCurrentLocation
    },
  },
  watch: {
    getPipeSelection: function (newValue) {
      this.currentPipe = newValue
      this.getStats()
      this.getCameras()
    },
  },
}
</script>
<style scoped>
.stat-icon {
  height: auto;
  width: 12%;
}
</style>
