import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import actions from './actions'

//
// GENERAL STORE SETTINGS AND MODULES
//
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    user,
    actions,
  },
  strict: debug,
})
