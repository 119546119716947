<template lang="pug">
div
  #container.mt-4.image
    img#image.img-fluid.img-desktop(
      :src='`data:image/png;base64, ${Source.image}`',
      @load='imageLoaded'
    )
    canvas#canvas(
      ref='myCanvas',
      @mousedown.left='mouseDown',
      @mouseup.left='mouseUp',
      @mousemove='mouseMove',
      @mousedown.middle='mouseMiddle',
      @mouseout='mouseOut'
    )
  b-container.mt-2
    b-row.justify-content-center
      div(@click='updateImageShowcase')
        b-button(
          :variant='Source.show ? "danger" : "success"',
          scale='2.5'
        ) {{ Source.show ? "Remove from Showcase" : "Add to Showcase" }}
    b-row#bbox-entry.justify-content-left.w-auto(
      v-for='(boundingbox, index) in BoundingBoxes',
      :key='index',
      @mouseover='focusIndex = index',
      @click='focusIndex = index',
      :class='focusIndex === index ? "bbox-selected" : ""'
    )
      b-col(cols='auto')
        b-iconstack.remove-icon 
          b-icon(
            icon='x',
            variant='danger',
            scale='1.5',
            shift-v='-6',
            @click='removeSelectedBox(boundingbox)'
          )
          b-icon(icon='circle', variant='danger', scale='1.5', shift-v='-6')
      b-col.pr-0.pl-1(cols='auto')
        .p-1(
          :class='focusIndex == index && focusElement == "class" ? "input-selected" : ""',
          @click='focusElement = "class"'
        )
          b-form-select(
            @change='clickLabelChange',
            :value='cOptions[focusIdxClass[index]]',
            :options='cOptions',
            size='sm',
            ref='classes'
          ) 
      b-col.pr-0.pl-1(cols='auto')
        .p-1(
          :class='focusIndex == index && focusElement == "size" ? "input-selected" : ""',
          @click='focusElement = "size"'
        )
          b-form-select(
            @change='clickSizeChange',
            :value='sOptions[focusIdxSize[index]]',
            :options='sOptions',
            size='sm',
            ref='sizes'
          )
      b-col(cols='auto')
        b-iconstack.remove-icon
          b-icon(
            @click='unskipImage',
            :icon='boundingbox.skip ? "plus-circle-fill" : "dash-circle-fill"',
            :variant='boundingbox.skip ? "info" : "warning"',
            scale='1.5',
            shift-v='-6'
          )

      b-col(cols='auto')
        p.m-0.mt-1(v-if='boundingbox.skip') Skipped
</template>

<script>
import { getFullDateString } from '../utils/getDate'
import { POST_SAMPLE, LABELS_GET, UPDATE_SHOWCASE } from '@/store/constants'
export default {
  props: {
    Source: null,
  },
  components: {},
  data() {
    return this.initialStates()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize)
  },
  mounted() {
    window.addEventListener('resize', this.windowResize)
    this.initialStates()
    this.$store.dispatch(LABELS_GET).then((result) => {
      this.cOptions = result.labels
      this.sOptions = result.sizes
      this.getLabels()
    })
  },

  watch: {
    focusIndex: function () {
      if (this.ctx) {
        this.getLabels()
        this.DrawRectangleArray()
      }
    },
  },
  methods: {
    initialStates() {
      return {
        labelModel: [],
        sizeModel: [],
        mycanvas: null,
        ctx: null,
        isDown: false,
        startX: null,
        startY: null,
        canvasOffset: null,
        xOffset: null,
        yOffset: null,
        rectHeight: null,
        rectWidth: null,
        BoundingBoxes: [],
        mouseX: null,
        mouseY: null,
        multiplierX: null,
        multiplierY: null,
        loading: true,
        imgContext: null,
        missingLabel: true,
        timeout: null,
        cOptions: [],
        sOptions: [],
        toggleDraw: true,
        newImageRequested: false,
        focusIndex: 0,
        focusIdxClass: [],
        focusIdxSize: [],
        focusElement: 'class',
        imageSkipped: false,
        imageConfirmed: false,
        imageDate: 'Date',
        imageReviewed: false,
        newImage: true,
      }
    },
    unskipImage() {
      this.BoundingBoxes[this.focusIndex].skip =
        !this.BoundingBoxes[this.focusIndex].skip
      this.$forceUpdate()
      this.updateImageData()
    },
    clickLabelChange(label) {
      this.BoundingBoxes[this.focusIndex].label = label
      this.updateImageData()
    },
    clickSizeChange(size) {
      this.BoundingBoxes[this.focusIndex].size = size
      this.updateImageData()
    },
    getLabels() {
      this.imageReviewed = false
      this.focusIdxClass = []
      this.focusIdxSize = []
      var hasLabel = false
      var hasSize = false
      this.BoundingBoxes.forEach((bbox) => {
        if (this.cOptions.includes(bbox.label)) {
          this.focusIdxClass.push(this.cOptions.indexOf(bbox.label))
          hasLabel = true
        } else {
          this.focusIdxClass.push(-1)
        }
        if (bbox.size && this.sOptions.includes(bbox.size)) {
          this.focusIdxSize.push(this.sOptions.indexOf(bbox.size))
          hasSize = true
        } else {
          this.focusIdxSize.push(-1)
        }
      })
      if (hasLabel && hasSize) {
        this.imageReviewed = true
        this.newImage = false
      }
      this.$forceUpdate()
    },
    windowResize() {
      if (this.imgContext) {
        this.getImageDims()
        this.DrawRectangleArray()
      }
    },
    removeSelectedBox(rect) {
      this.BoundingBoxes.splice(this.BoundingBoxes.indexOf(rect), 1)
      this.focusIndex = this.focusIndex - 1
      this.updateImageData()
      this.DrawRectangleArray()
    },
    getImageDims() {
      if (document.getElementById('image')) {
        this.imgContext = document.getElementById('image')
        this.mycanvas.height = this.imgContext.height
        this.mycanvas.width = this.imgContext.width
        this.multiplierX = this.imgContext.naturalWidth / this.imgContext.width
        this.multiplierY =
          this.imgContext.naturalHeight / this.imgContext.height
      }
    },

    imageLoaded() {
      this.mycanvas = document.getElementById('canvas')
      this.ctx = this.mycanvas.getContext('2d')

      document.getElementById('image').style.visibility = 'visible'
      if (this.Source.result.length > 0) {
        this.BoundingBoxes = this.Source.result
      }
      this.imageDate = getFullDateString(this.Source.utc_date)
      this.getLabels()
      this.loading = false
      this.focusElement = 'class'
      this.getImageDims()
      this.DrawRectangleArray()
    },
    mouseOut(e) {
      e.preventDefault()
      e.stopPropagation()
      this.isDown = false
    },
    mouseDown(e) {
      e.preventDefault()
      e.stopPropagation()

      this.xOffset = this.$refs['myCanvas'].getBoundingClientRect().x
      this.yOffset = this.$refs['myCanvas'].getBoundingClientRect().y
      this.startX = parseInt(e.clientX - this.xOffset)
      this.startY = parseInt(e.clientY - this.yOffset)
      this.rectHeight = null
      this.rectWidth = null
      this.mouseX = null
      this.mouseY = null

      this.isDown = true
    },
    mouseUp(e) {
      e.preventDefault()
      e.stopPropagation()
      this.isDown = false

      if (this.startX && this.startY && this.rectHeight && this.rectWidth) {
        this.BoundingBoxes.push({
          bbox: {
            x: Math.floor(this.startX * this.multiplierX),
            y: Math.floor(this.startY * this.multiplierY),
            w: Math.floor(this.rectWidth * this.multiplierX),
            h: Math.floor(this.rectHeight * this.multiplierY),
          },
          label: 'fish',
        })

        this.focusIndex = this.BoundingBoxes.length - 1
        this.updateImageData()
      }
      this.getLabels()
      this.DrawRectangleArray()
    },
    mouseMove(e) {
      e.preventDefault()
      e.stopPropagation()

      if (!this.isDown) {
        this.rectWidth = this.mouseX - this.startX
        this.rectHeight = this.mouseY - this.startY
        return
      }

      this.mouseX = parseInt(e.clientX - this.xOffset)
      this.mouseY = parseInt(e.clientY - this.yOffset)

      this.rectWidth = this.mouseX - this.startX
      this.rectHeight = this.mouseY - this.startY
      this.ctx.strokeStyle = 'red'
      this.ctx.lineWidth = 3
      this.ctx.clearRect(0, 0, this.mycanvas.width, this.mycanvas.height)
      this.ctx.strokeRect(
        this.startX,
        this.startY,
        this.rectWidth,
        this.rectHeight
      )
    },
    mouseMiddle(e) {
      e.preventDefault()
      e.stopPropagation()
      this.removeCurrentBox()
    },
    removeCurrentBox() {
      if (this.BoundingBoxes.length > 0) {
        this.BoundingBoxes.splice(
          this.BoundingBoxes.indexOf(this.BoundingBoxes[this.focusIndex]),
          1
        )
        this.focusIndex = this.focusIndex - 1
        this.updateImageData()
        this.DrawRectangleArray()
      }
    },
    DrawRectangleArray() {
      this.ctx.clearRect(0, 0, this.mycanvas.width, this.mycanvas.height)
      if (this.toggleDraw) {
        this.ctx.font = '20px Arial'
        this.ctx.fillStyle = 'yellow'
        this.BoundingBoxes.forEach((bbox, index) => {
          this.DrawRectangle(bbox, index)
        })
      }
    },
    DrawRectangle(result, index) {
      if (this.focusIndex == index) {
        this.ctx.strokeStyle = 'blue'
        this.ctx.lineWidth = 6
      } else {
        this.ctx.strokeStyle = 'red'
        this.ctx.lineWidth = 1
      }

      this.ctx.strokeRect(
        result.bbox.x / this.multiplierX,
        result.bbox.y / this.multiplierY,
        result.bbox.w / this.multiplierX,
        result.bbox.h / this.multiplierY
      )

      this.ctx.fillText(
        result.label,
        result.bbox.x / this.multiplierX,
        result.bbox.y / this.multiplierY
      )
    },
    updateImageShowcase() {
      this.$store
        .dispatch(UPDATE_SHOWCASE, this.Source.imageID)
        .then((result) => {
          this.Source.show = result
        })
    },
    updateImageData() {
      // console.log(this.Source)
      this.loading = true
      // this.Source.result = this.BoundingBoxes
      var newValue = {
        imageID: this.Source.imageID,
        new: {
          result: this.BoundingBoxes,
          reviewed: true,
        },
      }
      this.$emit('updateInfo', this.Source)
      this.updateTest(newValue)
    },
    updateTest(value) {
      if (value) {
        value.date = this.Source.utc_date
        value.pipe = this.Source.source
        value.filename = this.Source.filename
        this.$store.dispatch(POST_SAMPLE, value)
      }
    },
  },
}
</script>

<style scoped>
.b-icon.bi {
  vertical-align: middle;
}

.remove-icon:hover {
  /* transform: scale(1); */
  cursor: pointer;
}

.max-con {
  width: max-content;
}

#bbox-entry:hover {
  /* transform: scale(1); */
}

.bbox-selected {
}

.input-selected {
}

.img-desktop {
  position: absolute;
  z-index: 1;
  height: auto;
  width: 100%;
  max-height: 100%;
  max-width: 96.5%;
  visibility: hidden;
}

#canvas {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 20;
}
</style>
