<template lang="pug">
b-container(fluid)
  .formi.mt-3
    b-card.h-100(bg-variant='light')
      b-table#table.w-100(
        striped,
        hover,
        :items='log',
        :fields='fields',
        small,
        borderless,
        dark
      )
</template>

<script>
import { GET_DEBUG } from '../store/constants'

export default {
  data() {
    return {
      timer: null,
      log: [],
      fields: [
        { key: 'date', sortable: true },
        { key: 'state', sortable: true },
        { key: 'success', sortable: true },
        { key: 'msg', sortable: true },
        { key: 'next', sortable: true },
      ],
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    this.getDebug()
    this.timer = setInterval(() => {
      this.getDebug()
    }, 10000)
  },
  methods: {
    getDebug() {
      this.$store.dispatch(GET_DEBUG).then((result) => {
        if (result) {
          this.log = result.log.reverse()
        }
      })
    },
  },
}
</script>

<style scoped>
.code {
  display: block;
  white-space: pre-wrap;
}

.formi {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-msg {
  color: red;
}
#table {
  word-wrap: anywhere;
}

.remove-icon:hover {
  transform: scale(1.4);
  cursor: pointer;
}
</style>
