<script>
import { Line } from 'vue-chartjs'
import { STATS_LABEL } from '@/store/constants'
export default {
  extends: Line,
  props: {
    Label: String,
    Pipe: String,
  },

  methods: {
    updateChart() {},
    getCurrentStats() {
      if (this.Pipe && this.Label) {
        this.$store
          .dispatch(STATS_LABEL, { label: this.Label, source: this.Pipe })
          .then((result) => {
            var label = []
            var data = []
            result.forEach((element) => {
              label.push(
                `${element._id.day}/${element._id.month}/${element._id.year}`
              )
              data.push(element.count)
            })
            data.push(0)
            this.renderChart(
              {
                labels: label,
                datasets: [
                  {
                    label: this.Label,
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255, 0, 0, 0.50)',
                    pointBackgroundColor: 'rgba(1, 1, 255, 1)',
                    data: data,
                  },
                ],
              },
              { responsive: true, maintainAspectRatio: false }
            )
          })
      }
    },
  },
  watch: {
    Label: function() {
      this.getCurrentStats()
    },
  },
  mounted() {
    if (this.Label && this.Pipe) {
      this.getCurrentStats()
    }
  },
}
</script>
