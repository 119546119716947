<template lang="pug">
     b-container.thumbnail-list(
              fluid,
              draggable='false',)
              
              div(v-for='(img, index) in Data', :key='index')
                b-img.img-thumbnail(
                  :src='`data:image/png;base64,${img.thumbnail}`',
                  thumbnail,
                  draggable='false'
                  @click="imageSelected(img.imageID)")
</template>

<script>
export default {
  name: 'ThumbnailView',
  props: {
    Data: Array,
  },
  methods: {
    imageSelected(id) {
      this.$emit('imgSelected', id)
    },
  },
}
</script>

<style lang="stylus" scoped>
.img-thumbnail
  position: relative;
  height: 150px;
  width: 180px;
  padding: 0px;
  overflow: scroll;

.thumbnail-list
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: center;

.img-thumbnail:hover
  position: relative;
  transform: scale(1.1);
  transition: 0.1s;
  z-index: 1;
</style>
