//CONSTANTS

// MUTATIONS
export const LOGOUT = 'LOGOUT'
export const RESET = 'RESET'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const UPDATE_FAIL = 'UPDATE_FAIL'
export const TOGGLE_HELP = 'TOGGLE_HELP'
export const TOGGLE_DRAW = 'TOGGLE_DRAW'
export const LOADING = 'LOADING'
export const LOADING_DONE = 'LOADING_DONE'
export const SET_PERMS = 'SET_PERMS'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_OPTIONS = 'SET_OPTIONS'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const SET_USER = 'SET_USER'
export const UPDATE_C_U_PERMS = 'UPDATE_C_U_PERMS'
export const PIPE_SELECTION_CHANGE = 'PIPE_SELECTION_CHANGE'
export const UPDATE_PIPE_SELECTION = 'UPDATE_PIPE_SELECTION'

export const GET_GROUPS = "GET_GROUPS"
export const GET_GROUP_DATA = "GET_GROUP_DATA"
export const CONFIRM_GROUP = "CONFIRM_GROUP"
///AUTH///
/**
 * @description Validates user &path
 * @host /api/auth/valid
 */
export const AUTH = 'AUTH'

/**
 * @description LOGIN
 * @host /api/auth/login
 */
export const LOGIN = 'LOGIN'

/**
 * @description REGISTRATION
 * @host /api/auth/register
 */
export const REGISTER = 'REGISTER'

/**
 * @description Return user page paths
 * @host /api/user/status
 */
export const GET_USER_STATUS = 'GET_USER_STATUS'

/**
 * @async
 * @description Generates login token
 * @param Perms
 * @host /api/auth/generate
 */
export const GEN_TOKEN = 'GEN_TOKEN'

/**
 * @async
 * @host /api/user/list
 * @description return list of users
 */
export const GET_USERS = 'GET_USERS'

/**
 * @async
 * @host /api/user/update
 * @description Updates user password
 */
export const UPDATE_USER = 'UPDATE_USER'

/**
 * @description Return label statistics
 * @host /api/stats/total/label?label=
 */
export const STATS_LABEL = 'STATS_CURRENT'

/**
 * @description Return files for report zip
 * @host /api/stats/zip
 */
export const GET_ZIP = 'GET_ZIP'

/**
 * @description Return report query
 * @host /api/stats/table
 *
 */
export const GET_QUERY = 'GET_QUERY'

/**
 * @description Return total yearly data for chart
 * @host /api/stats/total
 */
export const GET_YEAR_DATA = 'GET_YEAR_DATA'

/**
 * @description Updates image data
 * @host /api/image/update
 */
export const POST_SAMPLE = 'POST_SAMPLE'

/**
 * @description Return images for image tool
 * @host /api/image/get/all
 */
export const GET_IMAGES = 'GET_IMAGES'

/**
 * @description Updates image to showcase
 * @host /api/image/showcase/update
 */
export const UPDATE_SHOWCASE = 'UPDATE_SHOWCASE'

/**
 * @description Returns showcased images
 * @host /api/image/showcase
 */
export const GET_SHOWCASE = 'GET_SHOWCASE'

/**
 * @description Updates user permissions
 * @host /user/update/perm
 */
export const UPDATE_PERMS = 'UPDATE_PERMS'

/**
 * @description Return total number of images from source
 * @host /api/image/count
 * @param source
 */
export const GET_TOTAL_IMAGES = 'GET_TOTAL_IMAGES'

/**
 * @description Returns all cameras
 * @host /api/image/cameras
 */
export const CAMERA_GET = 'CAMERA_GET'

/**
 * @description Removes camera with given index
 * @param index
 * @host /api/image/cameras/del
 */
export const CAMERA_DEL = 'CAMERA_DEL'

/**
 * @description Adds camera to DB
 * @host /api/images/cameras/add
 * @param Object camera info
 */
export const CAMERA_ADD = 'CAMERA_ADD'

/**
 * @description Returns all distinct labels in DB
 * @host api/fish/labels
 */
export const LABELS_GET = 'LABELS_GET'

/**
 * @description Returns default labels
 * @host /api/fish/default
 */
export const LABEL_DEFAULTS = 'LABEL_DEFAULTS'

/**
 * @description Sends message
 * @host POST /api/message/post
 */
export const SEND_MSG = 'SEND_MSG'

/**
 * @description Return message board messages
 * @host GET /api/message/get
 */
export const GET_MSG = 'GET_MSG'

/**
 * @description Updates labels if user modifies them
 * @host /api/fish/labels/update
 */
export const UPDATE_LABELS = 'UPDATE_LABELS'

/**
 * @description Returs all labels
 * @host api/fish/source
 */
export const GET_PIPES = 'GET_PIPES'

/**
 * @async
 * @description Gets user permission for report
 * @host /api/user/report/perms
 */

export const GET_REPORT_PERMS = 'GET_REPORT_PERMS'

/**
 * @description Return fish statistics
 * @host api/stats/all
 */
export const GET_STATS = 'GET_STATS'

/**
 * @description Return one image for report
 * @host /api/image/get
 */
export const IMAGE_GET = 'IMAGE_GET'

/**
 * @description Return user apikey if generated
 * @host /api/auth/apikey/get
 */
export const GET_APIKEY = 'GET_APIKEY'

/**
 * @description Generates apikey
 * @host /api/auth/apikey/generate
 */
export const SET_APIKEY = 'SET_APIKEY'

/**
 * @description Updates labels for statistics
 * @host /api/fish/labels/migratory/update
 */
export const UPDATE_STAT_LABELS = 'UPDATE_STAT_LABELS'

/**
 * @description returns debug log
 * @host /api/debug/worker
 */
export const GET_DEBUG = 'GET_DEBUG'

/**
 * @async
 * @description Returns current user permissions
 * @host /api/user/perms
 */
export const GET_CURRENT_USER_PERMS = 'GET_CURRENT_USER_PERMS'

export const GET_SOURCE_TAGS = 'GET_SOURCE_TAGS'
export const UPDATE_SOURCE_TAGS = 'UPDATE_SOURCE_TAGS'
export const RESET_PERMS = 'RESET_PERMS'
