<template lang="pug">
b-row.h-100
  b-col(lg='auto')
    b-list-group#ImageList(horizontal)
      b-list-group-item(v-for='(img, index) in this.Source', :key='index')
        boundBoxImage.h-100(
          :Source='img',
          :isMobile='isMobile',
          :Index='index',
          :style='Selected.includes(img.imageID) ? { border: "5px solid green", transform: "scale(1.02)", transition: "0.1s" } : { border: "none" }',
          :Selected='Selected.includes(img.imageID)',
          @EditClicked='EditClicked',
          @ImageSelected='ImageClicked'
        )
</template>

<script>
import boundBoxImage from './bboxImage.vue'
import { getFullDateString } from '../utils/getDate'
export default {
  props: {
    Source: null,
    isMobile: null,
    Date: null,
    Selected: null,
  },
  components: { boundBoxImage },
  data() {
    return this.initialStates()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize)
  },
  mounted() {
    window.addEventListener('resize', this.windowResize)
    // console.log(this.Source)
    // console.log(this.Selected)
    this.initialStates()
  },
  computed: {},
  watch: {
    Source: function () {
      // console.log('Source changed')
      Object.assign(this.$data, this.initialStates())
    },
    Selected: function () {
      console.log('Selected changed')
    },
  },
  methods: {
    initialStates() {
      return {
        currentImageIndex: null,
        mycanvas: null,
        ctx: null,
        BoundingBoxes: [],
        loading: true,
        focusIndex: 0,
        imagesSelected: [],
        imageConfirmed: false,
        imageDate: 'Date',
      }
    },
    getTimeString: getFullDateString,
    ImageClicked(id) {
      // console.log('imageClicked', index)
      // console.log(this.Selected)
      // console.log(id)
      if (this.Selected.includes(id)) {
        this.Selected.splice(this.Selected.indexOf(id), 1)
      } else {
        this.Selected.push(id)
      }
      this.$emit('ImageSelected', this.Selected)
    },
    EditClicked(index) {
      // console.log('editClicked', index)
      this.$emit('EditClicked', index)
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'list image image image'
    'list image image image'
    'list image image image';
}

.list-group-item {
  border: none;
  padding: none;
}
.test {
  border: 1px solid blue;
}
#labels {
  font-size: 0.5em;
}
#help {
  font-size: 0.7em;
}
.image {
  grid-area: image;
}
.list {
  grid-area: list;
}

.b-icon.bi {
  vertical-align: middle;
}

#ImageList {
  display: flex;
  flex-wrap: wrap;
}

.bbox-skipped {
  background-color: yellow;
  border: solid 3px blue;
}

.remove-icon:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.bbox-selected {
  background-color: lightgray;
  border: solid 3px blue;
}

.img-desktop {
  position: absolute;
  z-index: 1;
  height: auto;
  min-height: 25vw;
  max-height: 25vw;
  max-width: 60vw;
  min-width: 60vw;
  visibility: hidden;
}

.bbox-entry {
  min-width: 100%;
}

.bbox-item {
  min-width: 100%;
}
.image-selected {
  transform: scale(1.1);
  transition: 0.1s;
  z-index: 1;
  border: 6px solid green;
}
.thumbnail-list {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 50%; */
}
.img-thumbnail {
  position: relative;
  height: 150px;
  width: auto;
  padding: 0px;
  overflow: scroll;
}

/* .img-thumbnail:hover {
  position: relative;
  transform: scale(1.05);
  transition: 0.1s;
  z-index: 1;
} */

.img-mobile {
  position: absolute;
  z-index: 1;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

#canvas {
  position: relative;
  z-index: 20;
}
</style>
