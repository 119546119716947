import {
  LOGIN,
  LOGOUT,
  REGISTER,
  RESET,
  AUTH_SUCCESS,
  AUTH_FAIL,
  UPDATE_FAIL,
  LOADING,
  AUTH,
  LOADING_DONE,
  SET_PERMS,
  GET_USER_STATUS,
  SET_TOKEN,
  SET_APIKEY,
  REFRESH_TOKEN,
  GET_APIKEY,
  SET_USER,
  RESET_PERMS,
} from '../constants'

import ApiQuery from '../../utils/apiHandler'

import URI from '../../config/api'
import Vue from 'vue'

const state = {
  token: sessionStorage.getItem('token') || null,
  exp: sessionStorage.getItem('token') || null,
  errors: [],
  user: null,
  loading: false,
  perm: [],
  paths: [],
}

const getters = {
  auth: (state) => !!state.token,
  errors: (state) => state.errors,
  user: (state) => !!state.user,
  getUser: (state) => state.user,
  getToken: (state) => state.token,
  getExp: (state) => state.exp,
  getPerm: (state) => state.perm,
  path: (state) => state.paths,
}

const actions = {
  [GET_APIKEY]: async () => {
    var response = await ApiQuery('GET', '/auth/apikey/get')
    if (response.ok) {
      var json = await response.json()
      return json
    } else {
      return ''
    }
  },
  [SET_APIKEY]: async () => {
    var response = await ApiQuery('GET', '/auth/apikey/generate')
    if (response.ok) {
      var json = await response.json()
      return json
    } else {
      return ''
    }
  },

  [GET_USER_STATUS]: async ({ commit }) => {
    var response = await ApiQuery('GET', '/user/status')
    if (response.ok) {
      var json = await response.json()
      commit(SET_PERMS, json.paths)
      commit(SET_USER, json.user)
      return json.paths
    } else {
      return false
    }
  },
  [AUTH]: async ({ commit }, to) => {
    commit(LOADING)
    var response = await ApiQuery('GET', '/auth/valid', {
      to: JSON.stringify(to),
    })
    return response.ok
  },

  [REGISTER]: async ({ commit }, user) => {
    commit(RESET)
    return new Promise((resolve, reject) => {
      fetch(URI + '/auth/register', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            response.json().then((result) => {
              commit(AUTH_FAIL, result)
            })
            resolve(response)
          } else {
            resolve(response)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  [LOGIN]: async ({ commit, dispatch }, user) => {
    commit(RESET)
    return new Promise((resolve, reject) => {
      fetch(URI + '/auth/login', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            response.json().then((result) => {
              commit(AUTH_FAIL, result.message)
            })
            resolve(response)
          } else {
            response.json().then((result) => {
              commit(AUTH_SUCCESS, result)
              dispatch(GET_USER_STATUS)
              commit(RESET)
            })
            resolve(response)
          }
        })
        .catch((err) => {
          commit(AUTH_FAIL, err)
          reject(err)
        })
    })
  },
  [REFRESH_TOKEN]: ({ commit, dispatch }) => {
    commit(RESET)
    return new Promise((resolve, reject) => {
      fetch(URI + '/auth/refresh', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            dispatch(RESET_PERMS)
            commit(AUTH_FAIL)
            resolve(response)
          } else {
            response.json().then((result) => {
              commit(AUTH_SUCCESS, result)
              commit(RESET)
            })
            resolve(response)
          }
        })
        .catch((err) => {
          dispatch(RESET_PERMS)
          commit(AUTH_FAIL)
          reject(err)
        })
    })
  },
  [LOGOUT]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      fetch(URI + '/auth/logout', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          dispatch(RESET_PERMS)
          commit(LOGOUT)
          resolve(response)
        })
        .catch((err) => {
          commit(LOGOUT)
          reject(err)
        })
    })
  },
  [AUTH_SUCCESS]: ({ commit }, user) => {
    commit(AUTH_SUCCESS, user)
  },
  [RESET]: ({ commit }) => {
    commit(RESET)
  },
}

const mutations = {
  [SET_TOKEN]: (response) => {
    sessionStorage.setItem('token', response.token)
    sessionStorage.setItem('exp', response.exp)
  },
  [SET_PERMS]: (state, str) => {
    Vue.set(state, 'perm', str)
  },
  [RESET]: (state) => {
    state.errors = null
  },
  [LOGOUT]: (state) => {
    state.perm = null
    state.user = null
    state.errors = null
    state.token = null
    state.exp = null
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('exp')
  },
  [AUTH_SUCCESS]: (state, response) => {
    if (response.token) sessionStorage.setItem('token', response.token)
    if (response.exp) sessionStorage.setItem('exp', response.exp)
    state.user = response.user
    state.token = sessionStorage.getItem('token')
    state.exp = sessionStorage.getItem('exp')
    state.errors = null
  },
  [SET_USER]: (state, user) => {
    state.user = user
  },
  [AUTH_FAIL]: (state, err) => {
    state.perm = null
    state.errors = [err]
    state.user = null
    state.token = null
    state.exp = null
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('exp')
  },
  [UPDATE_FAIL]: (state, err) => {
    state.errors = err
  },
  [LOADING]: (state) => {
    state.loading = true
  },
  [LOADING_DONE]: (state) => {
    state.loading = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
