import {
  GET_QUERY,
  GET_YEAR_DATA,
  GET_ZIP,
  POST_SAMPLE,
  GET_GROUPS,
  GET_IMAGES,
  UPDATE_SHOWCASE,
  CONFIRM_GROUP,
  GET_SHOWCASE,
  STATS_LABEL,
  GET_TOTAL_IMAGES,
  CAMERA_ADD,
  CAMERA_GET,
  CAMERA_DEL,
  LABELS_GET,
  LABEL_DEFAULTS,
  SEND_MSG,
  GET_MSG,
  SET_OPTIONS,
  UPDATE_LABELS,
  GET_PIPES,
  GET_STATS,
  IMAGE_GET,
  UPDATE_STAT_LABELS,
  GET_DEBUG,
  GET_SOURCE_TAGS,
  GET_GROUP_DATA,
  UPDATE_SOURCE_TAGS,
  LOADING_DONE,
} from './constants'

import ApiQuery from '../utils/apiHandler'

const state = {
  classOptions: [],
  sizeOptions: [],
}

const getters = {
  getLabels: (state) => state.classOptions,
  getSizes: (state) => state.sizeOptions,
}

const actions = {
  [GET_DEBUG]: async () => {
    try {
      var response = await ApiQuery('GET', '/debug/worker')
      if (response.ok) {
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [IMAGE_GET]: async ({ commit }, id) => {
    try {
      var response = await ApiQuery('GET', '/image/get', { id: id })
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_STATS]: async ({ commit }, pipe) => {
    try {
      var response = await ApiQuery('GET', '/stats/all', { source: pipe })
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [UPDATE_SOURCE_TAGS]: async ({ commit }, sources) => {
    try {
      var response = await ApiQuery(
        'POST',
        '/fish/source/tags/save',
        {},
        { sources }
      )
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_SOURCE_TAGS]: async ({ commit }) => {
    try {
      var response = await ApiQuery('GET', '/fish/source/tags')
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_PIPES]: async ({ commit }) => {
    try {
      var response = await ApiQuery('GET', '/fish/source')
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_MSG]: async ({ commit }) => {
    try {
      var response = await ApiQuery('GET', '/message/get')

      if (response.ok) {
        commit(LOADING_DONE)

        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [SEND_MSG]: async ({ commit }, msg) => {
    try {
      var response = await ApiQuery('POST', '/message/post', {}, { msg })
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [UPDATE_STAT_LABELS]: async ({ commit }, labels) => {
    try {
      var response = await ApiQuery(
        'POST',
        '/fish/labels/migratory/update',
        {},
        labels
      )
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [UPDATE_LABELS]: async ({ commit }, labels) => {
    try {
      var response = await ApiQuery('POST', '/fish/labels/update', {}, labels)
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [LABEL_DEFAULTS]: async ({ commit }) => {
    try {
      var response = await ApiQuery('GET', '/fish/default')
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [LABELS_GET]: async ({ commit }) => {
    try {
      var response = await ApiQuery('GET', '/fish/labels')
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [CAMERA_DEL]: async ({ commit }, index) => {
    try {
      var response = await ApiQuery('GET', '/image/cameras/del', {
        index: index,
      })
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [CAMERA_GET]: async ({ commit }, id) => {
    try {
      if (id) {
        var response = await ApiQuery('GET', '/image/cameras', { source: id })
      } else {
        response = await ApiQuery('GET', '/image/cameras')
      }

      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [CAMERA_ADD]: async ({ commit }, payload) => {
    try {
      var response = await ApiQuery('POST', '/image/cameras/add', {}, payload)
      if (response) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_TOTAL_IMAGES]: async ({ commit }, source) => {
    try {
      var response = await ApiQuery('GET', '/image/count', {
        source: JSON.stringify(source),
      })
      if (response.ok) {
        commit(LOADING_DONE)
        var result = await response.json()
        console.log(result)
        return result
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },

  [GET_SHOWCASE]: async ({ commit }) => {
    try {
      var response = await ApiQuery('GET', '/image/showcase')
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },

  [UPDATE_SHOWCASE]: async ({ commit }, imageID) => {
    try {
      var response = await ApiQuery(
        'POST',
        '/image/showcase/update',
        {},
        { id: imageID }
      )
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [CONFIRM_GROUP]: async ({ commit }, images) => {
    try {

      var response = await ApiQuery('PUT', '/groups/update', {}, images
      )
      if (response.ok) {
        commit(LOADING_DONE)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_GROUP_DATA]: async ({ commit }, id) => {
    try {
      var response = await ApiQuery('GET', '/groups/get/data', {
        data: id,
      })
      if (response.ok) {
        commit(LOADING_DONE)
        var result = await response.json()
        // console.log(result)
        return result
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_GROUPS]: async ({ commit }, sort) => {
    try {
      var response = await ApiQuery('GET', '/groups/get/', {
        data: JSON.stringify(sort),
      })
      if (response.ok) {
        commit(LOADING_DONE)
        var result = await response.json()
        // console.log(result)
        return result
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_IMAGES]: async ({ commit }, sort) => {
    try {
      var response = await ApiQuery('GET', '/image/get/all', {

        data: JSON.stringify(sort),
      })
      if (response.ok) {
        commit(LOADING_DONE)
        var result = await response.json()
        // console.log(result)
        return result
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [POST_SAMPLE]: async ({ commit }, sample) => {
    try {
      var response = await ApiQuery('PUT', '/image/update', {}, sample)
      if (response.ok) {
        commit(LOADING_DONE)
        return response
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [STATS_LABEL]: async ({ commit }, params) => {
    try {
      var response = await ApiQuery(
        'GET',
        '/stats/total/label?label=' + params.label,
        { source: params.source }
      )
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_ZIP]: async ({ commit }, images) => {
    try {
      var response = await ApiQuery('POST', '/stats/zip', {}, { images })
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  [GET_QUERY]: async ({ commit }, selections) => {
    try {
      var response = await ApiQuery('POST', '/stats/table', {}, { selections })
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },

  [GET_YEAR_DATA]: async ({ commit }, pipe) => {
    try {
      var response = await ApiQuery('GET', '/stats/total', { source: pipe })
      if (response.ok) {
        commit(LOADING_DONE)
        return await response.json()
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
}

const mutations = {
  [SET_OPTIONS]: (state, result) => {
    state.classOptions = result.labels
    state.sizeOptions = result.sizes
  },
}

export default { actions, state, getters, mutations }
