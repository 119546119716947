<template lang="pug">
div
  b-navbar(type='dark', variant='dark', toggleable='sm')
    b-nav-toggle(target='nav-collapse')
    b-collapse#nav-collapse(is-nav)
      // left side
      b-navbar-nav.mx-auto
        b-nav-item.hover-scale(
          v-if='isLogged',
          :to='{ path: "/locations" }',
          exact,
          exact-active-class='active'
        ) Locations
        b-nav-item.hover-scale(
          v-if='isLogged && userPaths.indexOf("Stats") !== -1',
          :hidden='!!!pipeSelected',
          :to='{ path: "/statistics" }',
          exact,
          exact-active-class='active'
        ) Statistics
        b-nav-item.hover-scale(
          v-if='isLogged && userPaths.indexOf("Feed") !== -1',
          :hidden='!!!pipeSelected',
          :to='{ path: "/live" }',
          exact,
          exact-active-class='active'
        ) Streams
        b-nav-item.hover-scale(
          v-if='isLogged && userPaths.indexOf("Images") !== -1',
          :hidden='!!!pipeSelected',
          :to='{ path: "/images" }',
          exact,
          exact-active-class='active'
        ) Images
        b-nav-item.hover-scale(
          v-if='isLogged && userPaths.indexOf("Images2") !== -1',
          :hidden='!!!pipeSelected',
          :to='{ path: "/images2" }',
          exact,
          exact-active-class='active'
        ) Groups
        b-nav-item.hover-scale(
          v-if='isLogged && userPaths.indexOf("Preview") !== -1',
          :hidden='!!!pipeSelected',
          :to='{ path: "/preview" }',
          exact,
          exact-active-class='active'
        ) Preview
        b-nav-item.hover-scale(
          v-if='isLogged && userPaths.indexOf("Report") !== -1',
          :to='{ path: "/reports" }',
          exact,
          exact-active-class='active'
        ) Reports
        b-nav-item.hover-scale(
          v-if='!isLogged',
          :to='{ path: "/login" }',
          exact,
          exact-active-class='active'
        ) Sign in
        b-nav-item.hover-scale(
          v-if='!isLogged',
          :to='{ path: "/register" }',
          exact,
          exact-active-class='active'
        ) Register

        b-nav-item-dropdown(v-if='isLogged', right, type='dark')
          template(slot='button-content')
            b-avatar.hover-scale(size='sm', variant='info')
          b-dropdown-item.text-center(:to='{ path: "/user" }') Password
          b-dropdown-item.text-center(
            v-if='isLogged && userPaths.indexOf("Locations") !== -1',
            :to='{ path: "/locations/rename" }'
          ) Locations
          b-dropdown-item.text-center(
            v-if='isLogged && userPaths.indexOf("Labels") !== -1',
            :to='{ path: "/labels" }'
          ) Labels
          b-dropdown-item.text-center(
            v-if='isLogged && userPaths.indexOf("Cameras") !== -1',
            :to='{ path: "/cameras" }'
          ) Cameras
          b-dropdown-item.text-center(
            v-if='isLogged && userPaths.indexOf("Control") !== -1',
            :to='{ path: "/user/control" }'
          ) User Control
          b-dropdown-item.text-center(
            v-if='isLogged && userPaths.indexOf("Chat") !== -1',
            :to='{ path: "/chat" }'
          ) Message board
          b-dropdown-item.text-center(
            v-if='isLogged && userPaths.indexOf("Settings") !== -1',
            :to='{ path: "/settings" }'
          ) Statistics
          b-dropdown-item.text-center(
            v-if='isLogged && userPaths.indexOf("Api") !== -1',
            :to='{ path: "/api" }'
          ) API
          b-dropdown-item.text-center(
            v-if='isLogged && userPaths.indexOf("Debug") !== -1',
            :to='{ path: "/debug" }'
          ) Worker
          b-dropdown-divider
          b-dropdown-item.text-center(@click='logout') Logout
</template>

<script>
import { LOGOUT, PIPE_SELECTION_CHANGE } from '../store/constants'
export default {
  name: 'NavBar',
  data() {
    return {
      userPaths: [],
      userPipes: [],
      pipeSelected: null,
    }
  },
  props: {
    paths: null,
  },
  mounted() {
    this.pipeSelected = this.$store.getters.getCurrentLocation
    this.userPipes = this.$store.getters.getPipePerms
  },
  computed: {
    getCurrentLocation() {
      return this.$store.getters.getCurrentLocation
    },
    getPerms() {
      return this.$store.getters.getPipePerms
    },
    isLogged() {
      return this.$store.getters.auth
    },
  },
  watch: {
    getCurrentLocation: function (newValue) {
      this.pipeSelected = newValue
    },
    paths: function (newValue) {
      this.userPaths = newValue
    },
    getPerms: function (newValue) {
      this.userPipes = newValue
    },
    pipeSelected: function (newValue) {
      this.$store.dispatch(PIPE_SELECTION_CHANGE, newValue).then(() => {
        this.$forceUpdate()
      })
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.userPaths = []
        this.$router.push('/login').catch(() => {})
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.main-icon {
  border-radius: 50%;
}

.nav-link .btn {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: auto;
}

.hover-scale:hover {
  transform: scale(1.2);
}

.hover-scale {
  transition: all 0.2s ease-in-out;
}

.active {
  transform: scale(1.2);
}

.nav-item {
  padding-left: 1.5em;
}

.dropdown-menu {
  min-width: 0em;
}

a {
  font-size: large;
}
</style>
