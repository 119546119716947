<template lang="pug">
b-row.justify-content-center
  b-col.p-0
    img(
      :class='isMobile ? "img-mobile" : "img-desktop"',
      :src='`data:image/png;base64, ${Source.image}`',
      v-bind:id='`image${this.Index}`',
      @load='imageLoaded'
    )
    canvas(
      ref='myCanvas',
      v-bind:id='`canvas${this.Index}`',
      :style='{ position: "relative", zIndex: 20 }',
      @click='collapse()'
    )
    b-collapse(:id='`collapse-${this.Index}`', :visible='Selected')
      b-button(size='sm', @click='EditClicked') Edit
</template>

<script>
import { getFullDateString } from '../utils/getDate'
export default {
  props: {
    Source: null,
    isMobile: null,
    Index: null,
    Selected: null,
  },
  components: {},
  data() {
    return {
      BoundingBoxes: [],
      mycanvas: null,
      ctx: null,
      imgContext: null,
      multiplierX: null,
      multiplierY: null,
      imageDate: null,
      focusIndex: null,
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize)
  },
  mounted() {
    window.addEventListener('resize', this.windowResize)
  },
  computed: {},
  watch: {
    focusIndex: function () {
      this.DrawBoundingBoxes()
    },
    Source: function () {
      this.DrawBoundingBoxes()
    },
  },
  methods: {
    ImageSelected() {
      this.$emit('ImageSelected', this.Source.imageID)
    },
    EditClicked() {
      this.$emit('EditClicked', this.Index)
    },
    collapse() {
      this.ImageSelected()
      this.$root.$emit('bv::toggle::collapse', `collapse-${this.Index}`)
    },
    imageLoaded() {
      this.mycanvas = document.getElementById(`canvas${this.Index}`)
      this.ctx = this.mycanvas.getContext('2d')
      this.getImageDims()
      document.getElementById(`image${this.Index}`).style.visibility = 'visible'
      this.getBoundingBoxes()
      this.imageDate = getFullDateString(this.Source.utc_date)
      this.DrawBoundingBoxes()
    },
    getImageDims() {
      if (document.getElementById(`image${this.Index}`)) {
        this.imgContext = document.getElementById(`image${this.Index}`)
        this.mycanvas.height = this.imgContext.height
        this.mycanvas.width = this.imgContext.width
        this.multiplierX = this.imgContext.naturalWidth / this.imgContext.width
        this.multiplierY =
          this.imgContext.naturalHeight / this.imgContext.height
      }
    },
    getBoundingBoxes() {
      this.BoundingBoxes = []
      this.Source.result.forEach((element) => {
        this.BoundingBoxes.push({
          box: element.bbox,
          label: element.label,
          score: element.score,
        })
      })
    },

    DrawBoundingBoxes() {
      if (this.ctx) {
        this.ctx.clearRect(0, 0, this.mycanvas.width, this.mycanvas.height)

        this.ctx.font = '20px Arial'
        this.ctx.fillStyle = 'yellow'
        this.BoundingBoxes.forEach((bbox, index) => {
          this.DrawRectangle(bbox, index)
        })
      }
    },
    DrawRectangle(result, index) {
      if (this.focusIndex == index) {
        this.ctx.strokeStyle = 'blue'
        this.ctx.lineWidth = 6
      } else {
        this.ctx.strokeStyle = 'black'
        this.ctx.lineWidth = 1
      }
      this.ctx.strokeRect(
        result.box.x / this.multiplierX,
        result.box.y / this.multiplierY,
        result.box.w / this.multiplierX,
        result.box.h / this.multiplierY
      )

      this.ctx.fillText(
        result.label,
        result.box.x / this.multiplierX,
        result.box.y / this.multiplierY
      )
    },
    windowResize() {
      if (this.imgContext) {
        this.getImageDims()
        this.DrawBoundingBoxes()
      }
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'list image image image'
    'list image image image'
    'list image image image';
}
.list-group-item {
  padding: 1em;
}
.test {
  border: 1px solid blue;
}
#labels {
  font-size: 0.5em;
}
#help {
  font-size: 0.7em;
}
.image {
  grid-area: image;
}
.list {
  grid-area: list;
}

.b-icon.bi {
  vertical-align: middle;
}

.bbox-skipped {
  background-color: yellow;
  border: solid 3px blue;
}

.remove-icon:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.bbox-selected {
  background-color: lightgray;
  border: solid 3px blue;
}

.input-selected {
  border: 3px solid green;
}

.img-desktop {
  position: absolute;
  z-index: 1;

  height: auto;
  width: auto;
  min-height: 5vw;
  max-height: 8vw;
  max-width: auto;
  min-width: auto;
  /* visibility: hidden; */
}

.img-mobile {
  position: absolute;
  z-index: 1;
  max-height: 100vh;
  max-width: 100%;
  margin: auto;
}

#canvas {
  position: relative;
  z-index: 20;
}
</style>
