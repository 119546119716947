<template lang="pug">
b-container(fluid)
  b-card(bg-variant="light")
    h2.mb-3 Edit Labels
    b-row.justify-content-center.flex-wrap-reverse
      b-alert(show variant="danger") Do not change labels if you dont know how it affects the automated labeling-system.
    b-row.justify-content-center.flex-wrap-reverse
      b-alert(show variant="info") If you wish to reset to the default labeling values, button is at the bottom-left corner.
    b-row.justify-content-center.flex-wrap-reverse.mt-5
      b-col(sm="auto")
        b-list-group
          b-list-group-item(v-for='(label, index) in labels.labels' :key="index")
            b-icon.remove-icon(@click="updateLabels('class','up',index)" icon="arrow-up" variant="info" scale="1.2"  v-if="index>0")
            b-icon.remove-icon(@click="updateLabels('class','down',index)" icon="arrow-down" variant="info" scale="1.2" v-if="index <= labels.labels.length-2")
            b-iconstack.ml-3.remove-icon(@click="delClass(index)")
              b-icon(icon="x" variant="danger" scale="2" shift-v="")
              
            
            p.mb-0.mt-2 
              b {{index+1}}:  {{label}} 
               
        b-form-input.mt-3(v-model="labelName" placeholder="class")
        b-button.mt-2(@click="addClass()" :disabled="!labelName" variant="info") Add Class
      b-col(sm="auto")
        b-list-group
          b-list-group-item(v-for='(size, index) in labels.sizes' :key="index")
            b-icon.remove-icon(@click="updateLabels('size','up',index)" icon="arrow-up" variant="info" scale="1.2"  v-if="index>0")
            b-icon.remove-icon(@click="updateLabels('size','down',index)" icon="arrow-down" variant="info" scale="1.2" v-if="index <= labels.sizes.length-2")
            b-iconstack.ml-3.remove-icon(@click="delSize(index)")
              b-icon(icon="x" variant="danger" scale="2" shift-v="")
              
            p.mb-0.mt-2 
              b {{index+1}}: {{size}} 
            
          b-form-input.w-auto.mt-3(v-model="sizeName" placeholder="size")
          b-button.mt-2(@click="addSize()" :disabled="!sizeName" variant="info") Add Size
    b-row
      b-button.mt-2(@click="setDefaults()"  ) Defaults

</template>

<script>
import { LABELS_GET, LABEL_DEFAULTS, UPDATE_LABELS } from '../store/constants'
export default {
  data() {
    return {
      labels: [],
      labelName: '',
      sizeName: '',
    }
  },
  mounted() {
    this.getLabels()
  },
  methods: {
    resetStates() {
      this.labelName = ''
      this.sizeName = ''
    },
    delSize(index) {
      this.labels.sizes.splice(index, 1)
      this.$store.dispatch(UPDATE_LABELS, this.labels).then((result) => {
        this.labels = result
        this.resetStates()
      })
    },
    addSize() {
      this.labels.sizes.push(this.sizeName)
      this.$store.dispatch(UPDATE_LABELS, this.labels).then((result) => {
        this.labels = result
        this.resetStates()
      })
    },
    addClass() {
      this.labels.labels.push(this.labelName)
      this.$store.dispatch(UPDATE_LABELS, this.labels).then((result) => {
        this.labels = result
        this.resetStates()
      })
    },
    delClass(index) {
      this.labels.labels.splice(index, 1)
      this.$store.dispatch(UPDATE_LABELS, this.labels).then((result) => {
        this.labels = result
        this.resetStates()
      })
    },
    updateLabels(array, dir, index) {
      if (array == 'class') {
        if (dir == 'up') {
          var temp = this.labels.labels[index - 1]
          this.labels.labels[index - 1] = this.labels.labels[index]
          this.labels.labels[index] = temp
        }
        if (dir == 'down') {
          temp = this.labels.labels[index + 1]
          this.labels.labels[index + 1] = this.labels.labels[index]
          this.labels.labels[index] = temp
        }
      }

      if (array == 'size') {
        if (dir == 'up') {
          temp = this.labels.sizes[index - 1]
          this.labels.sizes[index - 1] = this.labels.sizes[index]
          this.labels.sizes[index] = temp
        }
        if (dir == 'down') {
          temp = this.labels.sizes[index + 1]
          this.labels.sizes[index + 1] = this.labels.sizes[index]
          this.labels.sizes[index] = temp
        }
      }
      this.$store.dispatch(UPDATE_LABELS, this.labels).then((result) => {
        this.labels = result
        this.resetStates()
      })
      this.$forceUpdate()
    },
    setDefaults() {
      this.$store.dispatch(LABEL_DEFAULTS).then((result) => {
        this.labels = result
        this.resetStates()
        this.$forceUpdate()
      })
    },
    getLabels() {
      this.$store.dispatch(LABELS_GET).then((result) => {
        this.labels = result
        this.resetStates()
        this.$forceUpdate()
      })
    },
  },
}
</script>

<style scoped>
.remove-icon:hover {
  transform: scale(1.4);
  cursor: pointer;
}
</style>
