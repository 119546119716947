<template lang="pug">
div.formi
    div(v-if='success')
        b-alert(variant="success" show) Registering complete
    div(class='form register-form',v-if="!success")
        b-card(bg-variant="light")
            h2 Register
            br
            b-form(@submit.prevent="onSubmit" ,@reset.prevent="onReset")
                b-form-group()
                    b-form-input(
                      size="sm" 
                      v-model="token",
                      type="text",
                      placeholder="Token",
                      maxlength="20"
                      minlength="1"
                      required)
                b-form-group(label="Email")
                    b-form-input(
                            size="sm"
                            v-model="email",
                            type="email",
                            placeholder="Email",
                            maxlength="50"
                            minlength="6"
                            required)
                b-form-group(label="Password")
                    b-form-input(
                      size="sm"
                            v-model="password",
                            type="password",
                            placeholder="Password",
                            maxlength="20",
                            minlength="4",
                            required)
                b-form-group(label="Confirm password")
                    b-form-input(
                      size="sm"
                            v-model="password2",
                            type="password",
                            placeholder="Repeat Password",
                            maxlength="20"
                            minlength="4"
                            required)
                div.mt-3(v-for="(error, index) in errors", :key="index")
                    p.error-msg(
                        v-for="(msg, index) in error",
                        :key="index") {{msg}}
                b-form-group.mt-3
                    b-row
                        b-col
                            b-button(type="submit", variant="primary" size="sm") Register
                        b-col
                            b-button(type="reset",variant="danger" size="sm") Reset
            h5.mt-5 Password
            p.m-0 - at least 8 characters
            p.m-0 - must contain at least 1 uppercase letter
            p.m-0 - 1 lowercase letter, and 1 number
            p.m-0 - Can contain special characters

</template>
<script>
import { REGISTER, RESET } from '../store/constants'
export default {
  name: 'Register',
  data() {
    return {
      success: false,
      email: '',
      password: '',
      password2: '',
      token: '',
    }
  },
  computed: {
    errors() {
      return this.$store.getters.errors
    },
  },
  mounted() {
    this.$store.dispatch(RESET)
  },
  methods: {
    onSubmit() {
      const { email, password, password2, token } = this
      this.$store
        .dispatch(REGISTER, {
          email,
          password,
          password2,
          token,
        })
        .then((res) => {
          if (res.ok) {
            this.success = true
            this.$router.push('/login')
          }
        })
    },

    onReset() {
      this.email = ''
      this.password = ''
      this.password2 = ''
      this.username = ''
      this.$store.dispatch(RESET)
    },
  },
}
</script>

<style scoped>
.error-msg {
  color: red;
}

.formi {
  display: flex;
  align-items: top;
  justify-content: center;
  padding-top: 3%;
}
</style>
