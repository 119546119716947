function getFullDateString(date) {
  var d = new Date(date)

  var day = d.getDate()
  var month = d.getMonth() + 1
  var year = d.getFullYear()
  var hour = d.getHours()
  var minute = d.getMinutes()
  var second = d.getSeconds()

  if (month.toString().length < 2) month = '0' + month
  if (hour.toString().length < 2) hour = '0' + hour
  if (minute.toString().length < 2) minute = '0' + minute
  if (second.toString().length < 2) second = '0' + second

  var oneJan = new Date(d.getFullYear(), 0, 1)
  var numberOfDays = Math.floor((d - oneJan) / (24 * 60 * 60 * 1000))
  var result = Math.ceil((d.getDay() + 1 + numberOfDays) / 7)

  return (
    day +
    '/' +
    month +
    '/' +
    year +
    ' - ' +
    hour +
    ':' +
    minute +
    ':' +
    second +
    ' - week ' +
    result
  )
}

function getFullDateStringNoWeek(date) {
  var d = new Date(date)

  var day = d.getDate()
  var month = d.getMonth() + 1
  var year = d.getFullYear()
  var hour = d.getHours()
  var minute = d.getMinutes()
  var second = d.getSeconds()

  if (month.toString().length < 2) month = '0' + month
  if (hour.toString().length < 2) hour = '0' + hour
  if (minute.toString().length < 2) minute = '0' + minute
  if (second.toString().length < 2) second = '0' + second


  return (
    day +
    '/' +
    month +
    '/' +
    year +
    ' - ' +
    hour +
    ':' +
    minute +
    ':' +
    second
  )
}


function getWeek(date) {
  var d = new Date(date)
  var oneJan = new Date(d.getFullYear(), 0, 1)
  var numberOfDays = Math.floor((d - oneJan) / (24 * 60 * 60 * 1000))
  var result = Math.ceil((d.getDay() + 1 + numberOfDays) / 7)

  return (
    'week ' +
    result
  )
}

function getDateString(date) {
  var d = new Date(date)

  var day = d.getDate()
  var month = d.getMonth() + 1
  var year = d.getFullYear()
  var hour = d.getHours()
  var minute = d.getMinutes()
  var second = d.getSeconds()

  if (month.toString().length < 2) month = '0' + month
  if (day.toString().length < 2) day = '0' + day
  if (hour.toString().length < 2) hour = '0' + hour
  if (minute.toString().length < 2) minute = '0' + minute
  if (second.toString().length < 2) second = '0' + second

  return year + '-' + month + '-' + day
}

function getTimeString(date) {
  //date_parse(date)
  // var d = new Date(date)

  // var hour = d.getHours()
  // var minute = d.getMinutes()
  // var second = d.getSeconds()

  // if (hour.toString().length < 2) hour = '0' + hour
  // if (minute.toString().length < 2) minute = '0' + minute
  // if (second.toString().length < 2) second = '0' + second

  // return hour + ':' + minute + ':' + second
  return date_parse(date)
}

function date_parse(date) {
  date = date.toString()
  date = date.split("T")
  date = date[1].split(".")
  return date[0]
}

export { getFullDateString, getDateString, getTimeString, getWeek, getFullDateStringNoWeek }
