<template lang="pug">
b-container(fluid)
  b-card(bg-variant="light")
    h2.mb-3 Cameras
    //b-row.justify-content-center.flex-wrap-reverse
      b-alert(show variant="danger") To maximize security, 3rd-party embedded video player is disabled and only redirection to host is provided on "Streams" page.
    h4 Guide:
    p Example. camera alias: "1a2b3c4" , name/location: "RapidRiver"
    b-row(v-if="error_msg").justify-content-center.mt-3
      b-alert(show variant="danger") {{error_msg}}
    b-row.justify-content-md-center.flex-wrap-reverse.mt-3
      b-form-input.w-auto.ml-2.mr-2(v-model="camAlias" placeholder="alias")
      b-form-input.w-auto.ml-2.mr-2(v-model="camName" placeholder="name")
      b-form-select(v-model="camId", :options="pipes" ).w-auto
      b-button.mt-2.ml-2(@click="addCameraAlias()" :disabled="!camAlias && !camName") Add camera
    b-row.justify-content-center.flex-wrap-reverse.mt-3
      b-list-group.w-auto
        b-list-group-item(v-for='(url, index) in cameras' :key="index") 
          p 
            b {{url.name}}
          p {{url.source}}
          p {{url.url}}
            b-iconstack.ml-3.remove-icon(@click="removeCameraUrl(url._id)")
              b-icon(icon="x" variant="danger" scale="1.5" shift-v="")
              b-icon(icon="circle" variant="danger" scale="1.5" shift-v="")

</template>

<script>
import { CAMERA_GET, CAMERA_ADD, CAMERA_DEL } from '../store/constants'
export default {
  data() {
    return {
      cameras: [],
      camAlias: null,
      camName: null,
      camId: null,
      pipes: [],
      error_msg: null,
    }
  },

  mounted() {
    this.$store.dispatch(CAMERA_GET, null).then((result) => {
      this.cameras = result.cameras
      this.pipes = result.sources
    })
  },
  methods: {
    addCameraAlias() {
      if (this.camAlias && this.camName && this.camId) {
        const { camAlias, camName, camId } = this
        this.$store
          .dispatch(CAMERA_ADD, { camAlias, camName, camId })
          .then((result) => {
            this.cameras = result.cameras
            this.camAlias = null
            this.camName = null
            this.camId = null
            if (result.message) {
              this.error_msg = result.message
              setTimeout(() => {
                this.error_msg = null
              }, 4000)
            }
          })
      }
    },
    removeCameraUrl(id) {
      this.$store.dispatch(CAMERA_DEL, id).then((result) => {
        this.cameras = result
      })
    },
    initCameraTab() {},
  },
}
</script>

<style scoped>
.formi {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-icon:hover {
  transform: scale(1.4);
  cursor: pointer;
}
</style>
