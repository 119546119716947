<template lang="pug">
#app
  banner#banner
  navbar#navbar(:paths='getPaths')
  router-view#route-view
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueAxios from 'vue-axios'
import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)

//components
import navbar from '@/components/navbar.vue'
import banner from '@/components/banner.vue'

import {
  LABELS_GET,
  GET_USER_STATUS,
  GET_CURRENT_USER_PERMS,
} from './store/constants'

export default {
  name: 'App',
  data() {
    return {
      paths: [],
    }
  },
  components: {
    navbar,
    banner,
  },
  computed: {
    getPaths: function () {
      return this.paths
    },
    authenticated: function () {
      return this.$store.getters.auth
    },
  },
  watch: {
    authenticated: function (newValue) {
      if (!newValue) {
        this.$router.push('/login')
      } else {
        if (sessionStorage.getItem('token')) {
          this.$store.dispatch(GET_CURRENT_USER_PERMS)
          this.$store.dispatch(LABELS_GET)
          this.$store.dispatch(GET_USER_STATUS).then((result) => {
            this.paths = result
          })
        }
      }
    },
  },
  beforeMount() {
    if (sessionStorage.getItem('token')) {
      this.$store.dispatch(GET_CURRENT_USER_PERMS)
      this.$store.dispatch(LABELS_GET)
      this.$store.dispatch(GET_USER_STATUS).then((result) => {
        this.paths = result
      })
    }
  },
  mounted() {
    if (!this.$store.getters.getCurrentLocation) {
      this.$router.push('/locations')
    }
  },
}
</script>

<style>
body,
html {
  min-height: 100% !important;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#banner {
  height: 11vh;
}

#route-view {
  height: 81vh !important;
}

#navbar {
  height: 6vh;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.grid-container {
  display: grid;
  grid-template-columns: 5em auto;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'sidebar content';
  height: inherit;
}
.sidebar {
  grid-area: sidebar;
  height: 100%;
  width: 5em;
  position: fixed;
}
.content {
  grid-area: content;
}
</style>
