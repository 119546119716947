<template lang="pug">
b-container.h-100(fluid)
  h1.mt-3 {{ locationSelected }}
  b-row.justify-content-center(v-if='userPipes.length > 0')
    b-list-group.p-0(horizontal='sm', small)
      b-list-group-item.m-0.p-1
        b-form-select.w-auto(v-model='pipeSelected', :options='userPipes')
      b-list-group-item.m-0.p-1(v-if='pipeSelected != null')
        b-button.m-1(
          @click='asc',
          size='sm',
          :variant='sort == 1 ? "success" : ""'
        ) Oldest
      b-list-group-item.m-0.p-1(v-if='pipeSelected != null')
        b-button.m-1(
          @click='desc',
          size='sm',
          :variant='sort == -1 ? "success" : ""'
        ) Newest

  b-row#data-area.justify-content-center(
    align-v='stretch',
    v-if='pipeSelected != null',
    md='auto'
  )
    b-modal#edit-modal(
      ok-only,
      :title='selectedImage.date',
      v-model='imageSelected',
      v-if='selectedImage',
      size='xl',
      @hidden='modalHidden'
    )
      editBox(
        v-if='selectedImage.image && isAdmin',
        :Source='selectedImage',
        @updateInfo='updateInfoTable'
      )

    b-col#grouplist.justify-content-center(cols='3')
      div(v-for='(group, index) in groupList', :key='index')
        #GroupButton.justify-content-center.w-100.h-100
          b-row.w-100.justify-content-center.m-0.p-1
            b-col#listGroupItem.p-0(
              cols='9',
              :style='selected_group === group ? { backgroundColor: "gray" } : { backgroundColor: "" }',
              @click='getImages(group)'
            )
              .group-btn.h-100.m-0.w-auto
                b-row#dateInfo.justify-content-center.m-0.p-1(
                  align-v='stretch'
                )
                  b.text-wrap(variant='dark') {{ getFullDateStringNoWeek(group.start_date) }}
                  b.text-wrap.ml-1(variant='dark') {{ getWeek(group.start_date) }}
                b-row#imageInfo.justify-content-center.m-0.p-1(
                  align-v='stretch'
                )
                  b-badge.text-wrap(variant='dark') Images: {{ group.images.length }}
                b-row#labelInfo.justify-content-center.m-0.p-1(
                  align-v='stretch'
                )
                  b-badge.text-wrap.mr-1(
                    variant='warning',
                    v-for='(label, index) in group.result',
                    :key='index'
                  ) {{ label }}
            b-col(cols='3', align-v='stretch')
              b-row.justify-content-center.p-1(align-v='stretch')
              b-row.justify-content-center.p-1(align-v='stretch')
                b-button(
                  size='sm',
                  :variant='selected_group === group ? "success" : "light"',
                  :hidden='selected_group != group',
                  @click='confirmGroup(group)'
                ) {{ `OK (${selectedImages.length})` }}

    b-col#group-data.justify-content-center(cols='9')
      b-spinner.vertical-center(
        v-if='imagesLoading',
        :style='{ width: "5rem", height: "5rem" }'
      )
      groupperBox#bbox-container.h-100(
        :visible='imageList.length > 0',
        :Source='imageList',
        :isMobile='isMobile',
        :Selected='selectedImages',
        @ImageSelected='ChildImageSelected',
        @EditClicked='ChildEditClicked'
      )
</template>

<script>
import editBox from '../components/edit-box'
import groupperBox from '../components/groupper'
import { getFullDateStringNoWeek, getWeek } from '../utils/getDate'
import { UPDATE_SHOWCASE, GET_TOTAL_IMAGES } from '@/store/constants'
import {
  GET_CURRENT_USER_PERMS,
  GET_GROUPS,
  GET_GROUP_DATA,
  GET_REPORT_PERMS,
  CONFIRM_GROUP,
} from '../store/constants'

export default {
  components: { groupperBox, editBox },
  data() {
    return {
      selectedImages: [],
      imageSelected: false,
      selectedImage: null,
      selected_group: null,
      groupList: [],
      showcasedImages: [],
      imageListShow: [],
      imageList: [],
      loading: false,
      isMobile: null,
      imagesLoading: true,
      totalImageCount: 0,
      sort: 1,
      locationSelected: null,
      pipeSelected: null,
      userPipes: [],
      //testing
      infoTable: [],
      sizeOptions: [],
      classes: [],
      pipes: [],
      permissions: false,
      edit: false,
    }
  },
  computed: {
    isAdmin() {
      return ['admin', 'developer', 'moderator'].includes(
        this.$store.getters.getUser.status
      )
    },
    getCurrentLocation() {
      return this.$store.getters.getCurrentLocation
    },
  },
  watch: {
    getCurrentLocation: function (newValue) {
      this.locationSelected = newValue
    },

    pipeSelected: function () {
      this.getGroups()
    },
  },
  mounted() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    this.locationSelected = this.$store.getters.getCurrentLocation
    this.$store.dispatch(GET_CURRENT_USER_PERMS).then((result) => {
      this.userPipes = []
      result.forEach((item) => {
        var temp = item.split(' ')
        if (temp[1] == this.locationSelected) {
          this.userPipes.push(item)
        }
      })
    })
    if (this.pipeSelected) {
      this.getGroups()
    }
  },

  beforeDestroy() {},
  methods: {
    modalHidden() {
      // console.log('modalHidden')
      this.getImages(this.selected_group)
      // console.log(this.selectedImages)
    },
    ChildImageSelected(index_array) {
      // console.log('edit selected ', index_array)
      this.selectedImages = index_array
    },
    ChildEditClicked(index) {
      // console.log(index)
      this.selectedImage = this.imageList[index]
      this.imageSelected = true
    },
    getLabelsByUserPerms() {
      this.$store.dispatch(GET_REPORT_PERMS).then((result) => {
        if (result.pipe.length > 0) {
          this.sizeOptions = result.size
          this.classes = result.label
          this.pipes = result.pipe
          this.permissions = true
        } else {
          this.permissions = false
        }
      })
      setTimeout(() => {
        this.loaded = true
      }, 1000)
    },
    confirmGroup(group) {
      this.imagesLoading = true
      this.loading = true
      this.$store
        .dispatch(CONFIRM_GROUP, {
          group: group,
          selected: this.selectedImages,
        })
        .then((result) => {
          if (result) {
            this.selectedImages = []
            this.getGroups()
          }
        })
    },

    selectGroup(id) {
      // console.log('Select Group: ' + id)
      this.$store.dispatch(GET_GROUP_DATA, id)
    },
    getFullDateStringNoWeek: getFullDateStringNoWeek,
    getWeek: getWeek,
    asc() {
      this.sort = 1
      this.getGroups()
      document.activeElement.blur()
    },
    desc() {
      this.sort = -1
      this.getGroups()
      document.activeElement.blur()
    },
    updateShowCase(imageID) {
      this.$store.dispatch(UPDATE_SHOWCASE, imageID)
    },
    updateInfoTable(newValue) {
      // console.log(this.imageList)
      // console.log(newValue)
      // console.log(this.imageList.indexOf(newValue))
      this.imageList[this.imageList.indexOf(newValue)] = newValue
      this.$forceUpdate()
    },

    getGroups() {
      var userPipePermissions = this.$store.getters.getPipePerms
      var pipeID = []
      userPipePermissions.forEach((element) => {
        var pipeString = element.split(' ')
        if (pipeString[1] == this.pipeSelected) pipeID.push(pipeString[0])
      })
      this.imageList = []
      this.loading = true
      this.groupList = []

      this.$store
        .dispatch(GET_GROUPS, {
          sort: this.sort,
          id: this.pipeSelected.split(' ')[0],
          source: this.locationSelected,
        })
        .then((result) => {
          this.groupList = result
          this.loading = false
          this.imagesLoading = false
        })
    },
    getImages(id) {
      if (id !== this.selected_group) this.selectedImages = []
      this.selected_group = id
      this.imagesLoading = true
      this.loading = true
      // console.log(id)
      this.imageList = []
      this.$store.dispatch(GET_GROUP_DATA, id._id).then((result) => {
        // console.log(result)
        this.selectedGroupDate = id.start_date
        this.imageList = result
        this.imagesLoading = false
        this.loading = false
      })
      this.$store
        .dispatch(GET_TOTAL_IMAGES, {
          id: this.pipeSelected.split(' ')[0],
          source: this.locationSelected,
        })
        .then((result) => {
          this.totalImageCount = result
        })
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'list bbox bbox bbox'
    'list bbox bbox bbox'
    'list bbox bbox bbox';
}

.bbox-area {
  grid-area: bbox;
}
.list-area {
  grid-area: list;
}
.image-selected {
  transform: scale(1.1);
  transition: 0.5s;
  z-index: 1;
  border: 6px solid green;
}

.nav-icon:hover {
  transition: 0.2s;
  cursor: pointer;
}

#bbox-container {
  overflow: scroll;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

.vertical-center {
  /* text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; */
  position: absolute;
  top: 45%;
  padding: 10px;
}

#group-data {
  height: 100%;
  overflow-y: hidden;
}

#grouplist {
  height: 100%;
  /* border: 1px solid #ccc; */
  flex-wrap: wrap;
  white-space: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
}

#group-label {
  width: 100px;
}

.reviewed {
  height: 50px im !important;
  width: 50px;
}

.fill-parent {
  width: 100%;
  height: 100%;
}

.thumbnail-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: center;
}

.img-thumbnail {
  position: relative;
  height: 100px;
  width: 120px;
  padding: 0px;
  overflow: scroll;
}

#listGroupItem {
  border: solid 2px grey;
  border-radius: 10px;
}

#listGroupItem:hover {
  background-color: rgb(209, 198, 198);
  cursor: pointer;
}

.group-btn:hover {
  background-color: rgb(209, 198, 198);
  cursor: pointer;
}

#GroupButton {
  display: inline;
}

.img-thumbnail:hover {
  position: relative;
  transform: scale(1.1);
  transition: 0.1s;
  z-index: 1;
}

.icon {
  float: left;
  position: absolute;
  z-index: 2;
}

.list-group-item {
  border: none;
}

#data-area {
  height: 80%;
}

.align-horizontal {
  display: flex;
  justify-content: center;
}
.sticky {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
