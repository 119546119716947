<template lang="pug">
b-container(fluid)
  div.formi.mt-3
    b-card(bg-variant="light")
      h2.mb-4 Change password
      h4.mb-5 {{email}}
      b-form(@submit.prevent="onSubmit")
        b-form-group(label="New Password")
          b-form-input(
            v-model="newPassword"
            type="password"
            required
            maxlength="20"
            minlength="4"
            size="sm")
        b-form-group(label="New Password again")
          b-form-input(
            v-model="newPassword2"
            type="password"
            :state="passwordsMatch"
            required
            maxlength="20"
            minlength="4"
            size="sm")
        b-form-group(label="Current Password")
          b-form-input(
            v-model="password"
            type="password"
            required
            maxlength="20"
            minlength="4"
            size="sm")
      
        
        b-button.mt-3(type="submit", variant="info") Change password
        div.mt-3(v-if='success')
          b-alert(variant="success" show) Password changed.
        div.mt-3(v-if="errors" v-for="(error, index) in errors", :key="index")
          p.error-msg(
          v-for="(msg, index) in error",
          :key="index") {{msg}}
</template>

<script>
import { UPDATE_USER } from '../store/constants'

export default {
  data() {
    return {
      email: '',
      userInfo: null,
      newPassword: null,
      newPassword2: null,
      password: null,
      success: false,
    }
  },
  computed: {
    passwordsMatch() {
      if (this.newPassword && this.newPassword2) {
        if (
          this.newPassword.length > 0 &&
          this.newPassword2 == this.newPassword
        ) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    errors() {
      return this.$store.getters.errors
    },
    getEmail: function() {
      return this.$store.getters.getUser
    },
  },
  watch: {
    getEmail: function(value) {
      if (value) {
        this.email = value.email
        this.$forceUpdate()
      }
    },
  },
  mounted() {},
  methods: {
    resetForm() {
      this.newPassword = null
      this.newPassword2 = null
      this.password = null
    },
    onSubmit() {
      const { userInfo, newPassword, newPassword2, password } = this
      this.$store
        .dispatch(UPDATE_USER, {
          userInfo,
          newPassword,
          newPassword2,
          password,
        })
        .then((res) => {
          if (res) {
            this.success = true
            this.resetForm()
          }
        })
    },
  },
}
</script>

<style scoped>
.formi {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-msg {
  color: red;
}

.remove-icon:hover {
  transform: scale(1.4);
  cursor: pointer;
}
</style>
