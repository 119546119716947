<template lang="pug">
b-container(fluid)
  div.formi.mt-3
    b-card(bg-variant="light")
      h4 API key
      b-form-input.mt-3.w-auto.m-auto(:value="apikey")
      b-button.mt-3(@click="generateApikey" variant="info") Get new API key
      VueJsonPretty.mt-4(:data="jsonHelp" :deep="3")
</template>

<script>
import { SET_APIKEY, GET_APIKEY } from '../store/constants'
import json_Help from '../config/json-guide.json'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      apikey: '',
      jsonHelp: json_Help,
    }
  },
  mounted() {
    this.$store.dispatch(GET_APIKEY).then((result) => {
      if (result) {
        this.apikey = result
      }
    })
  },
  methods: {
    generateApikey() {
      this.$store.dispatch(SET_APIKEY).then((result) => {
        this.apikey = result
      })
    },
  },
}
</script>

<style scoped>
.code {
  display: block;
  white-space: pre-wrap;
}

.formi {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-msg {
  color: red;
}

.remove-icon:hover {
  transform: scale(1.4);
  cursor: pointer;
}
</style>
